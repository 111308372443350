@charset "UTF-8";
/*----------------------------------------------------------------------
    * BootNews - Magazine and Blog bootstrap template
    * Copyright 2020 Bootstrap.News
    * Licensed under Bootstrap.News (https://bootstrap.news/license/)
------------------------------------------------------------------------*/
/*------------------------------------
	General
------------------------------------*/
* {
  box-sizing: border-box;
  outline: none;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

body {
  font-family: "Open Sans", sans-serif;
}

.wrapper {
  font-size: 0.9375rem;
}

main {
  position: relative;
}

a:hover {
  text-decoration: none;
}

a:focus {
  text-decoration: none;
}

h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
  line-height: 1.5rem;
  font-weight: 500;
}

figure {
  margin-bottom: 0;
}

object {
  margin-bottom: -0.3125rem;
}

.register-mobile {
  text-align: center;
}

.register-mobile li {
  font-weight: 700;
  display: inline;
}

.cursors {
  cursor: pointer;
}

pre {
  overflow: auto;
  background-color: #f6f6f6;
  padding: 4px 10px;
  border: none;
  border-radius: 0;
  margin-top: 24px;
}

ol ul {
  margin-left: 1rem;
  margin-bottom: 1rem;
}

.tags-list, .comment-list {
  padding-left: 0;
}

:focus {
  outline: 0;
}

a:focus {
  outline: 0;
}

button:focus {
  outline: 0;
}

/*------------------------------------
	Color
------------------------------------*/
a {
  color: #6c63ff;
}

h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
  color: #1b1b1b;
}

h1 > a, h2 > a, h3 > a, h4 > a, h5 > a, h6 > a, .h1 > a, .h2 > a, .h3 > a, .h4 > a, .h5 > a, .h6 > a {
  color: #1b1b1b;
  -webkit-font-smoothing: antialiased;
}

h1 > .btn a, h2 > .btn a, h3 > .btn a, h4 > .btn a, h5 > .btn a, h6 > .btn a, .h1 > .btn a, .h2 > .btn a, .h3 > .btn a, .h4 > .btn a, .h5 > .btn a, .h6 > .btn a {
  color: #fff;
}

h1 > .btn a:hover, h2 > .btn a:hover, h3 > .btn a:hover, h4 > .btn a:hover, h5 > .btn a:hover, h6 > .btn a:hover, .h1 > .btn a:hover, .h2 > .btn a:hover, .h3 > .btn a:hover, .h4 > .btn a:hover, .h5 > .btn a:hover, .h6 > .btn a:hover {
  color: #fff;
}

p {
  color: #666666;
}

/*------------------------------------
  Extra color
------------------------------------*/
.bg-blue {
  background-color: #4285f4 !important;
}

.text-blue {
  color: #4285f4 !important;
}

.border-blue {
  border-color: #4285f4 !important;
}

.bg-purple {
  background-color: #7952B3 !important;
}

.text-purple {
  color: #7952B3 !important;
}

.border-purple {
  border-color: #7952B3 !important;
}

.bg-pink {
  background-color: #d6456a !important;
}

.text-pink {
  color: #d6456a !important;
}

.border-pink {
  border-color: #d6456a !important;
}

.bg-yellow {
  background-color: #ffc107 !important;
}

.text-yellow {
  color: #ffc107 !important;
}

.border-yellow {
  border-color: #ffc107 !important;
}

.bg-black {
  background-color: #000 !important;
}

.text-black {
  color: #000 !important;
}

.border-black {
  border-color: #000 !important;
}

.bg-themes {
  background: #fff !important;
}

.bg-light-dark, .bg-light-black {
  background: #f6f6f6 !important;
}

.black a {
  color: #000;
}

.white a {
  color: #fff;
}

/*------------------------------------
	Animate.css
------------------------------------*/
.animations {
  -webkit-animation-duration: .3s;
  animation-duration: .3s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

@keyframes slideInUp {
  0% {
    transform: translateY(1rem);
    opacity: 0;
  }
  100% {
    transform: translateY(0rem);
    opacity: 1;
  }
  0% {
    transform: translateY(1rem);
    opacity: 0;
  }
}

@-webkit-keyframes slideInUp {
  0% {
    -webkit-transform: transform;
    -webkit-opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
    -webkit-opacity: 1;
  }
  0% {
    -webkit-transform: translateY(1rem);
    -webkit-opacity: 0;
  }
}

.slideInUp {
  -webkit-animation-name: slideInUp;
  animation-name: slideInUp;
}

@keyframes slideInDown {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -5%, 0);
    transform: translate3d(0, -5%, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@-webkit-keyframes slideInDown {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -5%, 0);
    transform: translate3d(0, -5%, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

.slideInDown {
  -webkit-animation-name: slideInDown;
  animation-name: slideInDown;
}

/*------------------------------------
	Google Fonts
------------------------------------*/
.font-family h1, .font-family h2, .font-family h3, .font-family h4, .font-family h5, .font-family h6, .font-family .h1, .font-family .h2, .font-family .h3, .font-family .h4, .font-family .h5, .font-family .h6 {
  font-family: "Roboto", Helvetica, sans-serif;
}

.font-family .navbar-nav, .font-family .breaking-box, .font-family .u-breadcrumb, .font-family .title-comments .authors, .font-family .prev-next-post a {
  font-family: "Roboto", Helvetica, sans-serif;
}

.post-content {
  font-family: Helvetica, sans-serif;
}

/*------------------------------------
	Card full
------------------------------------*/
.card-full {
  background-color: transparent !important;
  border: 0;
}

.card-full .card-title:last-child {
  margin-bottom: 0;
}

.card-full .card-body {
  padding: 0;
  padding-top: 1rem;
}

.card-full .no-gutters .card-body {
  padding: 1.25rem;
}

.card-img {
  min-height: 3rem;
}

.card-list li {
  padding: 0.5rem;
  margin-bottom: 0.5rem;
}

.card-list li a::before {
  content: "\f105";
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  font-size: 0.8rem;
  margin-right: 0.5rem;
  margin-left: -0.5rem;
}

.card-list li:hover a {
  color: #6c63ff;
}

.sub-card {
  margin-top: 1rem;
}

/*------------------------------------
	Main Nav
------------------------------------*/
.main-logo img {
  margin-top: 1rem;
  margin-bottom: 1rem;
  max-width: 14.875rem;
}

.logo-six {
  max-height: 2.5rem;
}

.mobile-logo {
  min-height: 40px;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  max-height: 2.5rem;
}

.top-menu, .footer-copyright {
  font-size: 0.875rem;
}

.full-nav {
  z-index: 20;
}

.main-nav .nav-link {
  font-size: 0.875rem;
  letter-spacing: 0.035rem;
  -webkit-font-smoothing: antialiased;
  padding: 1rem;
}

.navbar-uppercase > li > a {
  text-transform: uppercase;
  font-weight: 700;
}

.main-nav .dropdown-menu {
  border-width: 3px 0 0;
  border-color: #6c63ff;
}

@media (min-width: 992px) {
  .dropdown-menu {
    left: auto;
  }
  .dropdown-menu .dropdown-menu {
    left: 100%;
  }
  #showbacktop .logo-showbacktop {
    display: none;
  }
  #showbacktop .logo-showbacktop img {
    display: none;
  }
  #showbacktop.is-fixed {
    /* when the user scrolls down, we hide the header right above the viewport */
    width: 100%;
    top: -54px;
    -webkit-transition: -webkit-transform 0.2s;
    transition: -webkit-transform 0.2s;
    transition: transform 0.2s;
    transition: transform 0.2s, -webkit-transform 0.2s;
    background-color: rgba(255, 255, 255, 0.95) !important;
  }
  #showbacktop.is-fixed .logo-showbacktop img {
    display: none;
  }
  #showbacktop.is-visible {
    position: fixed;
    background: rgba(255, 255, 255, 0.95) !important;
    /* if the user changes the scrolling direction, we show the header */
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }
  #showbacktop.is-visible .logo-showbacktop {
    display: block;
    height: 2.3rem;
  }
  #showbacktop.is-visible .logo-showbacktop img {
    max-width: 10rem;
    max-height: 2.3rem;
    margin-bottom: 0.5rem;
    margin-right: 1rem;
    display: block;
  }
  .boxed #showbacktop.is-visible, .framed #showbacktop.is-visible {
    width: 90%;
  }
  #showbacktop.bg-black.is-fixed, #showbacktop.bg-secondary.is-fixed, #showbacktop.bg-dark.is-fixed {
    background: rgba(0, 0, 0, 0.95) !important;
    border-color: #495057 !important;
  }
  #showbacktop.bg-black.is-visible, #showbacktop.bg-secondary.is-visible, #showbacktop.bg-dark.is-visible {
    background: rgba(0, 0, 0, 0.95) !important;
    border-color: #495057;
    top: -58px;
  }
  #showbacktop.bg-black.is-visible.six-menu, #showbacktop.bg-secondary.is-visible.six-menu, #showbacktop.bg-dark.is-visible.six-menu {
    top: -59px;
  }
  #showbacktop.bg-white.is-visible.six-menu {
    top: -59px;
  }
  .admin-bar #showbacktop.is-fixed {
    top: -22px;
  }
  .admin-bar #showbacktop.is-fixed.six-menu {
    top: -37px;
  }
  .admin-bar .sticky-top {
    top: 32px;
  }
}

.main-menu.navbar-dark a {
  color: #f6f6f6 !important;
}

.main-menu.navbar-dark a:hover {
  color: #fff !important;
}

.main-menu.navbar-dark a.badge {
  color: #f6f6f6 !important;
  background-color: #6c63ff;
}

.main-menu.navbar-dark h1, .main-menu.navbar-dark h2, .main-menu.navbar-dark h3, .main-menu.navbar-dark h4, .main-menu.navbar-dark h5, .main-menu.navbar-dark h6, .main-menu.navbar-dark .h1, .main-menu.navbar-dark .h2, .main-menu.navbar-dark .h3, .main-menu.navbar-dark .h4, .main-menu.navbar-dark .h5, .main-menu.navbar-dark .h6 {
  color: #f6f6f6 !important;
}

.main-menu.navbar-dark h1 > a, .main-menu.navbar-dark h2 > a, .main-menu.navbar-dark h3 > a, .main-menu.navbar-dark h4 > a, .main-menu.navbar-dark h5 > a, .main-menu.navbar-dark h6 > a, .main-menu.navbar-dark .h1 > a, .main-menu.navbar-dark .h2 > a, .main-menu.navbar-dark .h3 > a, .main-menu.navbar-dark .h4 > a, .main-menu.navbar-dark .h5 > a, .main-menu.navbar-dark .h6 > a {
  color: #f6f6f6 !important;
}

.main-menu.navbar-dark h1:hover, .main-menu.navbar-dark h2:hover, .main-menu.navbar-dark h3:hover, .main-menu.navbar-dark h4:hover, .main-menu.navbar-dark h5:hover, .main-menu.navbar-dark h6:hover, .main-menu.navbar-dark .h1:hover, .main-menu.navbar-dark .h2:hover, .main-menu.navbar-dark .h3:hover, .main-menu.navbar-dark .h4:hover, .main-menu.navbar-dark .h5:hover, .main-menu.navbar-dark .h6:hover {
  color: #fff !important;
}

.main-menu.navbar-dark .hover-a:hover h1 > a, .main-menu.navbar-dark .hover-a:hover h2 > a, .main-menu.navbar-dark .hover-a:hover h3 > a, .main-menu.navbar-dark .hover-a:hover h4 > a, .main-menu.navbar-dark .hover-a:hover h5 > a, .main-menu.navbar-dark .hover-a:hover h6 > a, .main-menu.navbar-dark .hover-a:hover .h1 > a, .main-menu.navbar-dark .hover-a:hover .h2 > a, .main-menu.navbar-dark .hover-a:hover .h3 > a, .main-menu.navbar-dark .hover-a:hover .h4 > a, .main-menu.navbar-dark .hover-a:hover .h5 > a, .main-menu.navbar-dark .hover-a:hover .h6 > a {
  color: #6c63ff !important;
}

.main-menu.navbar-dark .dropdown-menu {
  background-color: #1b1b1b;
}

.main-menu.navbar-dark .dropdown-menu .dropdown-submenu {
  position: relative;
}

.main-menu.navbar-dark .dropdown-menu .dropdown-submenu .dropdown-menu {
  top: -12px;
}

.main-menu.navbar-dark .navbar-nav .nav-link {
  padding-left: 15px;
}

.main-menu.navbar-dark .navbar-nav > .nav-item.active, .main-menu.navbar-dark .navbar-nav > .nav-item:hover {
  background: #6c63ff;
  color: #f6f6f6;
}

.main-menu.navbar-dark .navbar-nav .nav-item .dropdown-menu {
  border-width: 4px 0 0;
  color: #f6f6f6;
  background-color: #000;
}

.main-menu.navbar-dark .navbar-nav .nav-item .dropdown-menu .dropdown-item {
  color: #f6f6f6;
}

.main-menu.navbar-dark .navbar-nav .nav-item .dropdown-menu .dropdown-item:hover, .main-menu.navbar-dark .navbar-nav .nav-item .dropdown-menu .dropdown-item:focus, .main-menu.navbar-dark .navbar-nav .nav-item .dropdown-menu .dropdown-item.active {
  color: #6c63ff !important;
  background: rgba(27, 27, 27, 0.85);
}

.main-menu.navbar-dark .navbar-nav .vertical-tabs .nav-tabs .nav-link {
  border-color: #1b1b1b;
}

.main-menu.navbar-dark .navbar-nav .vertical-tabs .nav-tabs .nav-link.active, .main-menu.navbar-dark .navbar-nav .vertical-tabs .nav-tabs .nav-link:hover {
  border-color: #000;
  background-color: #000 !important;
}

.six-menu {
  padding-top: 0px;
  padding-bottom: 0px;
}

.six-menu .navbar-nav {
  padding-top: 0px;
  padding-bottom: 0px;
}

.six-menu .navbar-nav .nav-link {
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
}

.six-menu .navbar-nav .nav-tabs .nav-link {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.six-menu.is-visible .navbar-nav {
  padding-top: 0px;
  padding-bottom: 0px;
}

.six-menu.is-visible .navbar-nav .nav-link {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

@media (min-width: 1200px) {
  .boxed #showbacktop.is-visible, .framed #showbacktop.is-visible {
    width: 1200px;
  }
}

@media (min-width: 992px) {
  .main-menu.navbar-dark .main-nav > li > a.active {
    color: #fff !important;
    background-color: #6c63ff !important;
  }
  .main-menu.navbar-dark .hover-mode .main-nav li:hover > a {
    color: #6c63ff !important;
  }
  .main-menu.navbar-dark .hover-mode .main-nav > li > a.active {
    color: #fff !important;
    background-color: #6c63ff !important;
  }
  .main-menu.navbar-dark .hover-mode .main-nav > li:hover > a {
    color: #fff !important;
    background-color: #6c63ff !important;
  }
  .navbar-nav.mx-auto li .dropdown-menu {
    left: inherit;
  }
}

@media (max-width: 991.98px) {
  .main-nav .nav-link {
    padding: .5rem;
  }
  .main-nav .dropdown-menu .menu-item-has-children .dropdown-menu {
    margin-left: 1rem;
  }
  .six-menu {
    padding-top: .5rem;
    padding-bottom: .5rem;
  }
  .six-menu .main-nav .nav-link {
    padding: 1rem .5rem .5rem;
  }
  /*No sticky mobile*/
  .no-md-sticky {
    position: relative;
  }
  #wpadminbar {
    position: fixed !important;
  }
}

/*------------------------------------
	Mega menu
------------------------------------*/
.navbar .dropdown-menu.mega {
  left: auto;
  right: 0;
}

.navbar .dropdown-menu.mega .block-area {
  margin: 1.5rem .5rem;
}

.navbar .dropdown-menu.mega.show {
  display: block;
}

.mega .title-nav {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.navbar-expand-lg .navbar-nav .mega-link > li > a.dropdown-item {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.mega-link .dropdown-item.active {
  background-color: #f6f6f6;
}

.mega-link .dropdown-item:active {
  background-color: #f6f6f6;
}

.before-caret a:before {
  content: "\f105";
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  display: inline-block;
  margin-right: 0.8rem;
  font-size: 0.8rem;
}

.mega ul.menu {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
  padding-left: 0;
  list-style: none;
}

.mega ul.menu > li {
  border-right: 1px solid #dee2e6;
}

.mega ul.menu > li:last-child {
  border-right: none;
}

.mega ul.menu > li > a {
  color: #161616;
  line-height: 2.5rem;
  font-weight: 500;
  font-size: 1rem;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  margin-bottom: 1rem;
  background: transparent;
}

.mega ul.menu > li > a::after {
  content: none !important;
}

.mega ul.menu > li:hover > a {
  color: #161616;
  background-color: transparent;
}

.mega ul.menu ul {
  padding-left: 0;
  list-style: none;
}

.mega ul.menu ul li a {
  display: block;
  width: 100%;
  padding: 0.5rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}

.mega ul.menu ul li a::after {
  content: none !important;
}

/*------------------------------------
	Dropdown submenu
------------------------------------*/
.navbar-nav .dropdown-toggle::after {
  content: "\f107";
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  margin-left: 0.8rem;
  font-size: 0.8rem;
  vertical-align: inherit;
  border-top: inherit;
  border-right: inherit;
  border-bottom: 0;
  border-left: inherit;
}

.navbar-nav .dropdown-menu {
  box-shadow: 0 0.4rem 2.2rem 0 rgba(27, 30, 36, 0.1);
}

.navbar-nav .dropdown-menu .dropdown-submenu, .navbar-nav .dropdown-menu .menu-item-has-children {
  position: relative;
}

.navbar-nav .dropdown-menu .dropdown-submenu .dropdown-menu, .navbar-nav .dropdown-menu .menu-item-has-children .dropdown-menu {
  top: -11px;
  left: 100%;
  margin-left: 0;
  margin-right: 0;
}

.navbar-nav .dropdown-menu .dropdown-submenu a.dropdown-toggle::after, .navbar-nav .dropdown-menu .dropdown-submenu > a::after, .navbar-nav .dropdown-menu .menu-item-has-children a.dropdown-toggle::after, .navbar-nav .dropdown-menu .menu-item-has-children > a::after {
  content: "\f105";
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  position: absolute;
  right: 2rem;
}

.navbar-nav .dropdown-menu .dropdown-submenu .dropdown-submenu .dropdown-menu, .navbar-nav .dropdown-menu .menu-item-has-children .dropdown-submenu .dropdown-menu {
  top: -11px;
}

.navbar-nav .dropdown-item:hover {
  color: #6c63ff;
}

.navbar-nav .nav-tabs .nav-link {
  color: #1b1b1b;
}

.dropdown-menu {
  margin-top: 0;
}

.mini {
  font-size: 0.6rem;
}

.dropdown-reverse {
  left: auto !important;
  right: 100% !important;
}

@media (max-width: 991.98px) {
  .navbar-expand-lg .navbar-nav .dropdown-menu .dropdown-submenu .dropdown-menu, .navbar-expand-lg .navbar-nav .dropdown-menu .menu-item-has-children .dropdown-menu {
    margin-left: 1.5rem;
  }
  .navbar-expand-lg .navbar-nav .dropdown-menu .dropdown-submenu a.dropdown-toggle::after, .navbar-expand-lg .navbar-nav .dropdown-menu .menu-item-has-children a.dropdown-toggle::after {
    content: "\f107";
    right: 0;
  }
  .navbar-expand-lg .navbar-nav .dropdown-toggle::after {
    float: right;
    margin-right: 2rem;
    margin-left: auto;
  }
  .navbar-expand-lg .navbar-nav .dropdown-menu.show {
    border-left-width: 0.2rem;
    border-left-style: solid;
    border-left-color: #6c63ff;
    border-top: 0;
  }
}

@media (max-width: 767.98px) {
  .navbar-expand-md .navbar-nav .dropdown-menu .dropdown-submenu .dropdown-menu, .navbar-expand-md .navbar-nav .dropdown-menu .menu-item-has-children .dropdown-menu {
    margin-left: 1.5rem;
  }
  .navbar-expand-md .navbar-nav .dropdown-menu .dropdown-submenu a.dropdown-toggle::after, .navbar-expand-md .navbar-nav .dropdown-menu .menu-item-has-children a.dropdown-toggle::after {
    content: "\f107";
    right: 0;
  }
  .navbar-expand-md .navbar-nav .dropdown-toggle::after {
    float: right;
    margin-right: 2rem;
    margin-left: auto;
  }
  .navbar-expand-md .navbar-nav .dropdown-menu.show {
    border-left-width: 0.2rem;
    border-left-style: solid;
    border-left-color: #6c63ff;
    border-top: 0;
  }
}

@media (min-width: 576px) {
  .border-right-sm {
    border-right: 1px solid #dee2e6;
  }
}

@media (min-width: 768px) {
  .navbar-expand-md .navbar-right > li:last-child > a {
    padding-right: 0;
  }
}

@media (min-width: 992px) {
  .hover-mode .main-nav .dropdown-menu.show {
    display: none;
  }
  .hover-mode .main-nav .dropdown-menu.mega.show {
    display: none;
  }
  .hover-mode .main-nav li:hover > a {
    color: #6c63ff;
    background-color: #f6f6f6;
  }
  .hover-mode .main-nav li:hover > .dropdown-menu {
    display: block;
  }
  .hover-mode .main-nav li:hover > .dropdown-menu.show {
    display: block;
  }
  .hover-mode .main-nav li:hover > .dropdown-menu.mega.show {
    display: block;
  }
  .hover-mode .main-nav > li:hover > a {
    background-color: transparent;
  }
  .navbar-dark .hover-mode li:hover > a {
    color: #6c63ff;
    background-color: #1b1b1b;
  }
  .navbar-light .hover-mode .main-nav .show > .nav-link {
    color: rgba(0, 0, 0, 0.9);
  }
  .navbar-light .hover-mode .main-nav li.show:hover > .nav-link {
    color: #6c63ff;
  }
  .navbar-expand-lg .navbar-right > li:last-child > a {
    padding-right: 0;
  }
  .navbar-expand-lg.navbar-dark .navbar-right > li:last-child > a {
    padding-right: 1rem;
  }
  .navbar-expand-lg .main-nav .nav-link {
    padding-right: 1rem;
    padding-left: 1rem;
  }
  .first-left-lg-0 > li:first-child > .nav-link {
    padding-right: 1rem;
    padding-left: 0;
  }
  .no-first-left .first-left-lg-0 > li:first-child > .nav-link {
    padding-left: 1rem;
  }
}

.ticker {
  width: 100%;
  margin: 10px auto;
}

.ticker div {
  display: inline-block;
  word-wrap: break-word;
}

.social-fly {
  top: 35%;
  padding-top: 4.5rem;
  position: fixed;
  left: 1rem;
}

.social-fly ul li {
  margin-bottom: 0.5rem;
}

@media (min-width: 1200px) {
  .social-fly {
    left: 2rem;
  }
}

.navbar-light .nav-item:hover > .nav-link {
  color: #6c63ff;
}

.dropright .no-caret::after {
  border: none;
  margin: 0;
}

.dropleft .no-caret::after {
  border: none;
  margin: 0;
}

.dropup .no-caret::after {
  border: none;
  margin: 0;
}

/*------------------------------------
	Vertical tabs
------------------------------------*/
.vertical-tabs {
  background: #f6f6f6;
}

.vertical-tabs .nav.nav-tabs {
  float: left;
  display: block;
  border-bottom: 0;
  margin-right: 1.5rem;
  border-right: #dee2e6 1px solid;
}

.vertical-tabs .nav-tabs .nav-link {
  border: 1px solid transparent;
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
}

.vertical-tabs .tab-content > .active {
  display: block;
  min-height: 10.3125rem;
}

.vertical-tabs ul li {
  margin-right: -1px;
}

.navbar-nav .vertical-tabs .nav-tabs .nav-link.active {
  color: #6c63ff;
  border-color: #dee2e6 #fff #dee2e6 #fff;
  background-color: #fff;
}

.navbar-dark .vertical-tabs {
  background: #1b1b1b;
  border-color: #1b1b1b;
}

/*------------------------------------
	Sidebar tabs
------------------------------------*/
.nav-tabs .nav-link.active, .nav-tabs .nav-item.show .nav-link {
  color: #fff;
  background-color: #6c63ff;
  border-color: #6c63ff;
}

.nav-tabs .nav-link {
  border-color: #dee2e6 #dee2e6 #fff;
}

.nav-tabs .nav-link.active:hover {
  border-color: #6c63ff;
}

.nav-tabs .nav-link.active:focus {
  border-color: #6c63ff;
}

.nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
  border-color: #dee2e6 #dee2e6 #fff;
}

/*------------------------------------
	Hamburger
------------------------------------*/
.hamburger-icon {
  display: block;
  width: 1.5rem;
  height: 1.5rem;
  position: relative;
  transform: rotate(0deg);
  transition: 0.5s ease-in-out;
  cursor: pointer;
}

.hamburger-icon span {
  display: block;
  position: absolute;
  height: 2px;
  width: 100%;
  opacity: 1;
  left: 0;
  transform: rotate(0deg);
  transition: 0.25s ease-in-out;
}

.hamburger-icon span:nth-child(1) {
  top: 0;
}

.hamburger-icon span:nth-child(2) {
  top: 0.6rem;
}

.hamburger-icon span:nth-child(3) {
  top: 0.6rem;
}

.hamburger-icon span:nth-child(4) {
  top: 1.2rem;
}

.hamburger-icon.open span:nth-child(1) {
  top: 0.5rem;
  width: 0;
  left: 50%;
}

.hamburger-icon.open span:nth-child(2) {
  transform: rotate(45deg);
}

.hamburger-icon.open span:nth-child(3) {
  transform: rotate(-45deg);
}

.hamburger-icon.open span:nth-child(4) {
  top: 1rem;
  width: 0;
  left: 50%;
}

.hamburger-dark span {
  background: #000;
}

.navbar-dark .navbar-toggler {
  border: none;
}

.navbar-dark .hamburger-icon span {
  background: #fff;
}

.navbar-light .hamburger-icon span {
  background: #1b1b1b;
}

/*------------------------------------
   Breadcrumb
------------------------------------*/
.u-breadcrumb {
  color: #666666;
  background: transparent;
  font-size: 90%;
}

.u-breadcrumb a {
  color: #666666;
}

.u-breadcrumb .breadcrumb-item {
  font-family: "Roboto", sans-serif;
}

.u-breadcrumb .breadcrumb-item a {
  color: #666666;
}

.u-breadcrumb .breadcrumb-item::before {
  display: inline-block;
  padding-right: 0.5rem;
  color: #666666;
  content: "»";
}

.u-breadcrumb .breadcrumb-item:first-child::before {
  display: none;
}

/*------------------------------------
 Lazyload images
------------------------------------*/
.image-wrapper {
  /*background color if image is loading slow*/
  background-color: #f6f6f6;
  overflow: hidden;
  width: 100%;
  position: relative;
  background: linear-gradient(to left, #f6f6f6 0%, #e0e0e0 15%, #f6f6f6 40%, #f6f6f6 100%);
  background-size: 400% 700% !important;
  background-position: center center !important;
  -webkit-animation: AnimationSea 1.5s infinite linear forwards;
  -moz-animation: AnimationSea 1.5s infinite linear forwards;
  -o-animation: AnimationSea 1.5s infinite linear forwards;
  animation: AnimationSea 1.5s infinite linear forwards;
}

@-webkit-keyframes AnimationSea {
  from {
    background-position: 100% 0;
  }
  to {
    background-position: 20% 0;
  }
}

@-moz-keyframes AnimationSea {
  from {
    background-position: 100% 0;
  }
  to {
    background-position: 20% 0;
  }
}

@-o-keyframes AnimationSea {
  from {
    background-position: 100% 0;
  }
  to {
    background-position: 20% 0;
  }
}

@keyframes AnimationSea {
  from {
    background-position: 100% 0;
  }
  to {
    background-position: 20% 0;
  }
}

.image-single-wrapper {
  width: 100%;
  min-height: 18rem;
}

.lazy {
  width: 100%;
  transition: 500ms ease-in-out;
  opacity: 0;
}

.lazy.loaded {
  opacity: 1;
}

.hover-a:hover .image-wrapper {
  opacity: 0.9;
}

.ajax-tabs {
  min-height: 20rem;
}

.loaders {
  opacity: .7;
  font-size: 2.5rem;
  text-align: center;
  margin-top: 100px;
}

/*------------------------------------
	Ratio Thumbnail images
	Tutorial calculate the ratio space example size 400x250px is 250/400*100 = 62.5%
------------------------------------*/
.ratio_category, .ratio_demo1, .ratio_demo2, .ratio_single, .ratio_203-114, .ratio_110-77, .ratio_115-80, .ratio_165-92, .ratio_180-123, .ratio_230-129, .ratio_251-141, .ratio_327-278, .ratio_376-376, .ratio_372-208, .ratio_400-250, .ratio_800-450, .ratio_763-422, .ratio_540-470, .ratio_540-460, .ratio_540-454, .ratio_540-304, .ratio_466-261, .ratio_360-202, .ratio_220-150, .ratio_230-230, .ratio_337-337, .ratio_99-68, .ratio_160-112, .ratio_640-640, .ratio_1140-535, .ratio_900-500, .ratio_950-500, .ratio_1200-900 {
  height: 0;
}

.ratio_category, .ratio_demo1 {
  padding-bottom: 25%;
}

.ratio_demo2 {
  padding-bottom: 55%;
}

.ratio_1108-520 {
  padding-bottom: 46.9314079%;
}

.ratio_1140-535 {
  padding-bottom: 46.9314079%;
}

.ratio_900-500, .ratio_763-422 {
  padding-bottom: 55.3555556%;
}

.ratio_466-261, .ratio_165-92, .ratio_230-129, .ratio_360-202, .ratio_203-114, .ratio_251-141, .ratio_372-208 {
  padding-bottom: 56.0085837%;
}

.ratio_800-450 {
  padding-bottom: 56.25%;
}

.ratio_540-304 {
  padding-bottom: 56.2962963%;
}

.ratio_400-250 {
  padding-bottom: 62.5%;
}

.ratio_single {
  padding-bottom: 66.6666667%;
}

.ratio_220-150, .ratio_99-68, .ratio_180-123 {
  padding-bottom: 68.1818182%;
}

.ratio_160-112, .ratio_115-80, .ratio_110-77 {
  padding-bottom: 69.5%;
}

.ratio_1200-900 {
  padding-bottom: 75%;
}

.ratio_540-454 {
  padding-bottom: 84%;
}

.ratio_327-278 {
  padding-bottom: 85.012905%;
}

.ratio_540-460 {
  padding-bottom: 85.1851852%;
}

.ratio_540-470 {
  padding-bottom: 87.037037%;
}

.ratio_640-640, .ratio_230-230, .ratio_337-337, .ratio_376-376 {
  padding-bottom: 99.999%;
}

/*------------------------------------
  Boxed and Framed Layout
------------------------------------*/
.boxed .wrapper, .framed .wrapper {
  position: relative;
  z-index: 1;
  margin: 0 auto;
}

.full-width {
  background-color: #fff;
}

.boxed.bg-repeat .wrapper, .boxed.bg-repeat2 .wrapper, .boxed.bg-repeat3 .wrapper, .framed.bg-repeat .wrapper, .framed.bg-repeat2 .wrapper, .framed.bg-repeat3 .wrapper {
  box-shadow: 0 0 3px #dee2e6;
}

.boxed {
  background-color: #f6f6f6;
}

.boxed .wrapper {
  background-color: #fff;
}

.framed {
  background-color: #f6f6f6;
}

.framed main, .framed .second-header, .framed .bg-nav-framed {
  background-color: rgba(255, 255, 255, 0.7);
}

@media (max-width: 767.98px) {
  .boxed .wrapper, .framed .wrapper {
    width: 100%;
  }
}

@media (min-width: 768px) {
  .boxed .wrapper, .framed .wrapper {
    width: 90%;
  }
  .framed {
    padding: 15px 0;
  }
}

@media (min-width: 1200px) {
  .boxed .wrapper, .framed .wrapper {
    width: 1200px;
  }
  .boxed .navbar, .framed .navbar {
    padding: 0.5rem 0rem;
  }
}

.top-section .block-area {
  margin-top: .5rem;
  margin-bottom: 5rem;
}

.social-top-left li {
  display: inline-block;
}

.grid-six .image {
  width: 100%;
}

.grid-six .caption {
  position: relative;
  width: 100%;
  top: 0;
  right: 0;
  padding: 2rem;
}

.card-banner {
  margin-bottom: 1.5rem;
  display: flex;
  position: relative;
  overflow: hidden;
  background-color: #fff;
  background-size: cover;
  border-radius: 5px;
}

.overlay-cover {
  width: 100%;
  border: #fff 1px solid;
}

.overlay {
  z-index: 10;
  padding: 1.25rem;
  color: #fff;
  -o-transition: .25s;
  -ms-transition: .25s;
  -moz-transition: .25s;
  -webkit-transition: .25s;
  transition: .25s;
}

.banner-shadow {
  border: #fff 1px solid;
  height: 100%;
  width: 100%;
}

.card-banner:hover .overlay {
  background-color: rgba(0, 0, 0, 0.08);
}

.banner-inner {
  padding: 7px 30px;
  background: #fff;
  display: inline-block;
  margin: 0;
}

.banner-inner .title {
  margin-bottom: 0;
}

@media (min-width: 992px) {
  .main-menu.navbar-dark .hover-mode .main-nav > li:hover > a, .main-menu.navbar-dark .hover-mode .main-nav > li > a.active {
    background-color: '.$color_primary.' !important;
  }
  .main-menu.navbar-dark .hover-mode .main-nav li:hover > a {
    color: '.$color_primary.' !important;
  }
  .main-menu.navbar-dark .hover-mode .main-nav > li:hover > a {
    color: #fff !important;
  }
  .logo-full .main-logo img {
    max-width: 25rem;
  }
  .search-top-right, .social-top-left {
    top: 50%;
    margin-top: -23px;
    position: absolute;
  }
}

@media (min-width: 768px) {
  .grid-six .image {
    width: 70%;
  }
  .grid-six .caption {
    position: absolute;
    width: 50%;
    top: 50%;
    right: 0;
    padding: 2rem;
    transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
  }
}

@media (max-width: 400px) {
  .top-space {
    top: 20%;
  }
}

/*------------------------------------
  Sidebar
------------------------------------*/
.right-sidebar {
  padding-left: 30px;
}

.left-sidebar {
  padding-right: 30px;
}

.right-sidebar {
  padding-left: 30px;
}

.left-sidebar {
  padding-right: 30px;
}

@media (min-width: 576px) {
  .right-sidebar-sm {
    padding-left: 30px;
  }
  .left-sidebar-sm {
    padding-right: 30px;
  }
}

@media (min-width: 768px) {
  .right-sidebar-md {
    padding-left: 30px;
  }
  .left-sidebar-md {
    padding-right: 30px;
  }
}

@media (min-width: 992px) {
  .right-sidebar-lg {
    padding-left: 30px;
  }
  .left-sidebar-lg {
    padding-right: 30px;
  }
}

@media (min-width: 1200px) {
  .right-sidebar-xl {
    padding-left: 30px;
  }
  .left-sidebar-xl {
    padding-right: 30px;
  }
}

.side-link li a, .sidebar-link li a {
  color: #1b1b1b;
}

.side-link li a.caret-collapse, .sidebar-link li a.caret-collapse {
  color: #6c63ff;
}

.side-link li a.caret-collapse.collapsed, .sidebar-link li a.caret-collapse.collapsed {
  color: #1b1b1b;
}

.side-link li a.caret-collapse.collapsed:hover, .sidebar-link li a.caret-collapse.collapsed:hover {
  color: #6c63ff;
}

.side-link li a:hover, .side-link li a.active, .sidebar-link li a:hover, .sidebar-link li a.active {
  background-color: transparent;
  color: #6c63ff;
}

.side-link li ul li a, .sidebar-link li ul li a {
  color: #1b1b1b;
}

.side-link li ul li a:hover, .sidebar-link li ul li a:hover {
  color: #6c63ff;
}

.sidebar-link li a {
  display: block;
  font-size: 1rem;
  padding: 0.5rem 0;
}

/*------------------------------------
  Footer
------------------------------------*/
.footer-logo {
  max-width: 80%;
}

.footer-page-list li {
  display: inline;
  margin-right: 1rem;
}

.copyright-text {
  margin-bottom: 0;
}

.bg-footer {
  background-image: url(../img/background/bg-footer.jpg);
  position: relative;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.bg-footer::before {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  content: '';
  background-color: rgba(27, 27, 27, 0.96);
}

@media (max-width: 767.98px) {
  .footer-copyright .navbar {
    -ms-flex-flow: column;
    flex-flow: column;
  }
  .footer-copyright .navbar .navbar-nav {
    margin-left: inherit !important;
  }
  .footer-copyright .navbar .navbar-nav.footer-nav {
    width: 100%;
    flex-direction: inherit;
    margin-bottom: 1rem;
  }
  .footer-copyright .navbar .navbar-nav.footer-nav li a {
    width: 100%;
    padding: 15px 0;
    border-bottom: rgba(255, 255, 255, 0.12) 1px solid;
  }
  .footer-copyright .navbar .navbar-nav.footer-nav li a:hover {
    color: #fff;
  }
  .footer-copyright .navbar .navbar-nav.footer-nav-right {
    margin-bottom: 5px;
  }
  .footer-light .footer-copyright .navbar .navbar-nav.footer-nav li a {
    border-color: #e0e0e0 !important;
  }
}

.footer-dark, .layout-dark {
  color: #dee2e6;
}

.footer-dark p, .footer-dark h1, .footer-dark h2, .footer-dark h3, .footer-dark h4, .footer-dark h5, .layout-dark p, .layout-dark h1, .layout-dark h2, .layout-dark h3, .layout-dark h4, .layout-dark h5 {
  color: #dee2e6;
}

.footer-dark a, .layout-dark a {
  color: #dee2e6;
  border-color: #dee2e6;
}

.footer-dark a:hover, .layout-dark a:hover {
  color: #6c63ff;
}

.footer-dark a.btn, .layout-dark a.btn {
  color: #dee2e6;
  border-color: #dee2e6;
}

.footer-dark a.btn:hover, .layout-dark a.btn:hover {
  color: #6c63ff;
}

.footer-dark li a, .layout-dark li a {
  color: #dee2e6;
}

.footer-dark li:hover a, .layout-dark li:hover a {
  color: #6c63ff;
}

.footer-light {
  color: #666666;
}

.footer-light p {
  color: #666666;
}

.footer-light a {
  color: #666666;
  border-color: #666666;
}

.footer-light a:hover {
  color: #000;
}

.footer-light a.btn {
  color: #666666;
  border-color: #666666;
}

.footer-light a.btn:hover {
  color: #6c63ff;
}

.footer-light li a {
  color: #666666;
}

.footer-light li:hover a {
  color: #000;
}

.footer-light .border-smooth {
  border-color: #e0e0e0 !important;
}

/*------------------------------------
	Big Grid
------------------------------------*/
.featured-one .ratio {
  padding-bottom: 40.888%;
  height: 0;
}

.featured-1 .ratio {
  padding-bottom: 84.888%;
  height: 0;
}

.featured-1 .newsbox .ratio {
  padding-bottom: 84.777%;
}

.featured-1 .newsbox > article:nth-child(1) {
  padding-top: 0;
  padding-bottom: 0.125rem;
  padding-right: 0.125rem;
}

.featured-1 .newsbox > article:nth-child(2) {
  padding-top: 0;
  padding-bottom: 0.125rem;
  padding-left: 0.125rem;
}

.featured-1 .newsbox > article:nth-child(3) {
  padding-top: 0.125rem;
  padding-bottom: 0.125rem;
  padding-right: 0.125rem;
}

.featured-1 .newsbox > article:nth-child(4) {
  padding-top: 0.125rem;
  padding-bottom: 0.125rem;
  padding-left: 0.125rem;
}

.featured-2 .ratio {
  padding-bottom: 85.2%;
  height: 0;
}

.featured-2 .newsbox .ratio {
  padding-bottom: 84.77777%;
}

.featured-2 .newsbox > article:nth-child(1) {
  padding-top: 0;
  padding-bottom: 0.125rem;
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
  max-width: 100%;
}

.featured-2 .newsbox > article:nth-child(1) .ratio {
  padding-bottom: 42.4%;
}

.featured-2 .newsbox > article:nth-child(2) {
  padding-top: 0.125rem;
  padding-right: 0.125rem;
  padding-bottom: 0.125rem;
}

.featured-2 .newsbox > article:nth-child(3) {
  padding-top: 0.125rem;
  padding-left: 0.125rem;
  padding-bottom: 0.125rem;
}

.featured-3 .ratio {
  padding-bottom: 55.3555556%;
  height: 0;
}

.featured-3 .newsbox .ratio {
  padding-bottom: 56.0085837%;
}

.featured-3 .newsbox > article:nth-child(1) {
  padding-bottom: 0.125rem;
}

.featured-3 .newsbox > article:nth-child(2) {
  padding-top: 0.125rem;
}

.featured-4 .ratio {
  padding-bottom: 84.55555%;
  height: 0;
}

.featured-4 .newsbox .ratio {
  padding-bottom: 84.77777%;
}

.featured-4 .newsbox > article:nth-child(1) {
  padding-top: 0;
  padding-bottom: 0.125rem;
  padding-right: 0.125rem;
}

.featured-4 .newsbox > article:nth-child(2) {
  padding-top: 0;
  padding-left: 0.125rem;
  padding-bottom: 0.125rem;
}

.featured-4 .newsbox > article:nth-child(3) {
  padding-top: 0.125rem;
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
  max-width: 100%;
}

.featured-4 .newsbox > article:nth-child(3) .ratio {
  padding-bottom: 41.89%;
}

.featured-5 .newsbox-left .ratio {
  padding-bottom: 84.77777%;
  height: 0;
}

.featured-5 .newsbox-left > article:nth-child(1) {
  padding-top: 0;
  padding-bottom: 0.125rem;
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
  max-width: 100%;
}

.featured-5 .newsbox-left > article:nth-child(1) .ratio {
  padding-bottom: 42.15%;
}

.featured-5 .newsbox-left > article:nth-child(2) {
  padding-top: 0.125rem;
  padding-right: 0.125rem;
  padding-bottom: 0.125rem;
}

.featured-5 .newsbox-left > article:nth-child(3) {
  padding-top: 0.125rem;
  padding-left: 0.125rem;
  padding-bottom: 0.125rem;
}

.featured-5 .newsbox-right .ratio {
  padding-bottom: 84.777%;
  height: 0;
}

.featured-5 .newsbox-right > article:nth-child(1) {
  padding-top: 0;
  padding-bottom: 0.125rem;
  padding-right: 0.125rem;
}

.featured-5 .newsbox-right > article:nth-child(2) {
  padding-top: 0;
  padding-bottom: 0.125rem;
  padding-left: 0.125rem;
}

.featured-5 .newsbox-right > article:nth-child(3) {
  padding-top: 0.125rem;
  padding-bottom: 0.125rem;
  padding-right: 0.125rem;
}

.featured-5 .newsbox-right > article:nth-child(4) {
  padding-top: 0.125rem;
  padding-bottom: 0.125rem;
  padding-left: 0.125rem;
}

.featured-6 .newsbox .ratio {
  padding-bottom: 99.999%;
  height: 0;
}

.featured-6 .newsbox > article:nth-child(1) {
  padding-top: 0.125rem;
  padding-bottom: 0.125rem;
  padding-right: 0;
  padding-left: 0;
}

.featured-6 .newsbox > article:nth-child(2) {
  padding-top: 0.125rem;
  padding-left: 0;
  padding-right: 0.125rem;
}

.featured-6 .newsbox > article:nth-child(3) {
  padding-top: 0.125rem;
  padding-right: 0;
  padding-left: 0.125rem;
}

.featured-7 .ratio {
  padding-bottom: 64.22%;
  height: 0;
}

.featured-7 .newsbox .ratio {
  padding-bottom: 43%;
}

.featured-7 .newsbox > article:nth-child(1) {
  padding-top: 0;
  padding-bottom: 0.125rem;
}

.featured-7 .newsbox > article:nth-child(2) {
  padding-top: 0.125rem;
  padding-bottom: 0.125rem;
}

.featured-7 .newsbox > article:nth-child(3) {
  padding-top: 0.125rem;
}

.featured-8 .newsbox-left .ratio {
  padding-bottom: 85%;
  height: 0;
}

.featured-8 .newsbox-left > article:nth-child(1) {
  padding-top: 0.125rem;
  padding-bottom: 0.125rem;
  padding-left: 0;
  padding-right: 0.125rem;
}

.featured-8 .newsbox-left > article:nth-child(2) {
  padding-top: 0.125rem;
  padding-bottom: 0.125rem;
  padding-left: 0.125rem;
  padding-right: 0;
}

.featured-8 .newsbox-center .ratio {
  padding-bottom: 85.6%;
  height: 0;
}

.featured-8 .newsbox-center > article:nth-child(1) {
  padding-top: 0.125rem;
  padding-bottom: 0.125rem;
  padding-left: 0;
  padding-right: 0;
}

.featured-8 .newsbox-right .ratio {
  padding-bottom: 85%;
  height: 0;
}

.featured-8 .newsbox-right > article:nth-child(1) {
  padding-top: 0.125rem;
  padding-bottom: 0.125rem;
  padding-left: 0;
  padding-right: 0.125rem;
}

.featured-8 .newsbox-right > article:nth-child(2) {
  padding-top: 0.125rem;
  padding-bottom: 0.125rem;
  padding-left: 0.125rem;
  padding-right: 0;
}

.featured-9 .newsbox-left .ratio, .featured-9 .newsbox-right .ratio {
  padding-bottom: 84.77777%;
  height: 0;
}

.featured-9 .newsbox-left > article:nth-child(1), .featured-9 .newsbox-right > article:nth-child(1) {
  padding-top: 0;
  padding-bottom: 0.125rem;
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
  max-width: 100%;
}

.featured-9 .newsbox-left > article:nth-child(1) .ratio, .featured-9 .newsbox-right > article:nth-child(1) .ratio {
  padding-bottom: 41.855555%;
}

.featured-9 .newsbox-left > article:nth-child(2), .featured-9 .newsbox-right > article:nth-child(2) {
  padding-top: 0.125rem;
  padding-right: 0.125rem;
  padding-bottom: 0.125rem;
}

.featured-9 .newsbox-left > article:nth-child(3), .featured-9 .newsbox-right > article:nth-child(3) {
  padding-top: 0.125rem;
  padding-left: 0.125rem;
  padding-bottom: 0.125rem;
}

.featured-10 .newsbox-left .ratio {
  padding-bottom: 84.77777%;
  height: 0;
}

.featured-10 .newsbox-left > article:nth-child(1) {
  padding-top: 0;
  padding-bottom: 0.125rem;
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
  max-width: 100%;
}

.featured-10 .newsbox-left > article:nth-child(1) .ratio {
  padding-bottom: 41.855555%;
}

.featured-10 .newsbox-left > article:nth-child(2) {
  padding-top: 0.125rem;
  padding-right: 0.125rem;
  padding-bottom: 0.125rem;
}

.featured-10 .newsbox-left > article:nth-child(3) {
  padding-top: 0.125rem;
  padding-left: 0.125rem;
  padding-bottom: 0.125rem;
}

.featured-10 .newsbox-right .ratio {
  padding-bottom: 84.77777%;
  height: 0;
}

.featured-10 .newsbox-right > article:nth-child(1) {
  padding-top: 0;
  padding-bottom: 0.125rem;
  padding-right: 0.125rem;
}

.featured-10 .newsbox-right > article:nth-child(2) {
  padding-top: 0;
  padding-left: 0.125rem;
  padding-bottom: 0.125rem;
}

.featured-10 .newsbox-right > article:nth-child(3) {
  padding-top: 0.125rem;
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
  max-width: 100%;
}

.featured-10 .newsbox-right > article:nth-child(3) .ratio {
  padding-bottom: 41.89%;
}

@media (max-width: 767.98px) {
  .featured-2 .ratio {
    padding-bottom: 50%;
  }
  .featured-2 .newsbox .ratio {
    padding-bottom: 84.77777%;
  }
  .featured-2 .newsbox > article:nth-child(1) .ratio {
    padding-bottom: 41.855555%;
  }
  .featured-4 .ratio {
    padding-bottom: 50%;
  }
  .featured-4 .newsbox .ratio {
    padding-bottom: 84.77777%;
  }
  .featured-6 .newsbox > article:nth-child(1) {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
}

@media (min-width: 576px) {
  .featured-3 .newsbox > article:nth-child(1) {
    padding-top: 0;
    padding-right: 0.125rem;
  }
  .featured-3 .newsbox > article:nth-child(2) {
    padding-top: 0;
    padding-left: 0.125rem;
  }
  .featured-7 .newsbox > article:nth-child(1) {
    padding-right: 0.125rem;
    padding-bottom: 0.125rem;
  }
  .featured-7 .newsbox > article:nth-child(2) {
    padding-top: 0;
    padding-left: 0.125rem;
    padding-bottom: 0.125rem;
  }
  .featured-7 .newsbox > article:nth-child(3) {
    padding-right: 0.125rem;
    padding-bottom: 0.125rem;
  }
}

@media (min-width: 768px) {
  .featured-6 .newsbox > article:nth-child(1) {
    padding-right: .1875rem;
  }
  .featured-6 .newsbox > article:nth-child(2) {
    padding-left: .09375rem;
    padding-right: .09375rem;
  }
  .featured-6 .newsbox > article:nth-child(3) {
    padding-left: .1875rem;
  }
  .featured-7 .ratio {
    padding-bottom: 64.22%;
  }
  .featured-7 .newsbox .ratio {
    padding-bottom: 43%;
  }
  .featured-7 .newsbox > article:nth-child(1) {
    padding-right: 15px;
    padding-left: 15px;
    padding-bottom: 0.125rem;
  }
  .featured-7 .newsbox > article:nth-child(2) {
    padding-top: 0.125rem;
    padding-right: 15px;
    padding-left: 15px;
    padding-bottom: 0.125rem;
  }
  .featured-7 .newsbox > article:nth-child(3) {
    padding-top: 0.125rem;
    padding-right: 15px;
    padding-left: 15px;
    padding-bottom: 0.125rem;
  }
  .featured-8 .newsbox-left > article:nth-child(2) {
    padding-left: 0;
    padding-right: 0.125rem;
  }
  .featured-8 .newsbox-center > article:nth-child(1) {
    padding-left: 0.125rem;
    padding-right: 0.125rem;
  }
  .featured-8 .newsbox-right > article:nth-child(1), .featured-8 .newsbox-right > article:nth-child(2) {
    padding-left: 0.125rem;
    padding-right: 0;
  }
}

@media (min-width: 992px) {
  .featured-3 .newsbox > article:nth-child(1) {
    padding-top: 0;
    padding-left: 1rem;
    padding-right: 15px;
  }
  .featured-3 .newsbox > article:nth-child(2) {
    padding-top: 0.125rem;
    padding-left: 1rem;
    padding-right: 15px;
  }
}

/*------------------------------------
	Block title
------------------------------------*/
.block-title-1 h1, .block-title-1 h2, .block-title-1 h3, .block-title-1 h4, .block-title-1 h5, .block-title-1 h6, .block-title-6 h1 span, .block-title-6 h2 span, .block-title-6 h3 span, .block-title-6 h4 span, .block-title-6 h5 span, .block-title-6 h6 span, .block-title-14 h1 span, .block-title-14 h2 span, .block-title-14 h3 span, .block-title-14 h4 span, .block-title-14 h5 span, .block-title-14 h6 span, .center-backline span, .center-backline-lg span, .six-block-title span {
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}

.center-backline:before, .center-backline-lg:before {
  content: "";
  position: absolute;
  height: 2px;
  top: 45%;
  background-color: #6c63ff;
  left: 0;
  right: 0;
}

.center-backline span, .center-backline-lg span {
  max-width: 100%;
  display: inline-block;
  position: relative;
  overflow: hidden;
}

.block-title-1 {
  position: relative;
  margin-bottom: 1.5rem;
  padding-bottom: 0.5rem;
  border-bottom: #dee2e6 2px solid;
}

.block-title-1 h1, .block-title-1 h2, .block-title-1 h3, .block-title-1 h4, .block-title-1 h5, .block-title-1 h6 {
  display: inline;
}

.block-title-2 {
  position: relative;
  margin-bottom: 1.5rem;
  padding-bottom: 0.5rem;
  border-bottom: #dee2e6 2px solid;
}

.block-title-2 h1, .block-title-2 h2, .block-title-2 h3, .block-title-2 h4, .block-title-2 h5, .block-title-2 h6 {
  display: inline;
}

.block-title-3 {
  position: relative;
  margin-bottom: 1.5rem;
  padding-bottom: 0.5rem;
  border-bottom: #dee2e6 2px solid;
}

.block-title-3 h1, .block-title-3 h2, .block-title-3 h3, .block-title-3 h4, .block-title-3 h5, .block-title-3 h6 {
  display: inline;
}

.block-title-4 {
  position: relative;
  margin-bottom: 1.5rem;
  padding-bottom: 0.5rem;
  border-bottom: #dee2e6 2px solid;
}

.block-title-4 h1, .block-title-4 h2, .block-title-4 h3, .block-title-4 h4, .block-title-4 h5, .block-title-4 h6 {
  display: inline;
}

.block-title-5 {
  position: relative;
  margin-bottom: 1.5rem;
  padding-bottom: 0.3rem;
  border-bottom: #dee2e6 2px solid;
}

.block-title-5 h1, .block-title-5 h2, .block-title-5 h3, .block-title-5 h4, .block-title-5 h5, .block-title-5 h6 {
  display: inline;
}

.block-title-5 h1 span, .block-title-5 h2 span, .block-title-5 h3 span, .block-title-5 h4 span, .block-title-5 h5 span, .block-title-5 h6 span {
  padding-bottom: 0.45rem;
  border-bottom: #dee2e6 2px solid;
}

.block-title-6 {
  position: relative;
  margin-bottom: 1.5rem;
}

.block-title-6 h1, .block-title-6 h2, .block-title-6 h3, .block-title-6 h4, .block-title-6 h5, .block-title-6 h6 {
  border-bottom: #dee2e6 2px solid;
}

.block-title-6 h1 span, .block-title-6 h2 span, .block-title-6 h3 span, .block-title-6 h4 span, .block-title-6 h5 span, .block-title-6 h6 span {
  margin-bottom: -2px;
  display: inline-block;
  position: relative;
}

.block-title-7 {
  position: relative;
  margin-bottom: 0;
}

.block-title-8 {
  position: relative;
  margin-bottom: 1.5rem;
}

.block-title-8 h1 span, .block-title-8 h2 span, .block-title-8 h3 span, .block-title-8 h4 span, .block-title-8 h5 span, .block-title-8 h6 span {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  position: relative;
  display: block;
}

.block-title-9 {
  position: relative;
  margin-bottom: 1.5rem;
}

.block-title-9 h1 span, .block-title-9 h2 span, .block-title-9 h3 span, .block-title-9 h4 span, .block-title-9 h5 span, .block-title-9 h6 span {
  padding-left: 1rem;
  border-left: #dee2e6 5px solid;
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  display: block;
  position: relative;
}

.block-title-10 {
  position: relative;
  margin-bottom: 1.5rem;
}

.block-title-10 h1 span, .block-title-10 h2 span, .block-title-10 h3 span, .block-title-10 h4 span, .block-title-10 h5 span, .block-title-10 h6 span {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  display: block;
  position: relative;
}

.block-title-11 {
  position: relative;
  margin-bottom: 0;
}

.block-title-11 h1 span, .block-title-11 h2 span, .block-title-11 h3 span, .block-title-11 h4 span, .block-title-11 h5 span, .block-title-11 h6 span {
  padding-left: 0;
}

.block-title-12 {
  position: relative;
  margin-bottom: 1.5rem;
  padding-bottom: 0.5rem;
  padding-top: 0.5rem;
}

.block-title-12 h1, .block-title-12 h2, .block-title-12 h3, .block-title-12 h4, .block-title-12 h5, .block-title-12 h6 {
  float: left;
  margin-right: 1rem;
}

.block-title-13 {
  position: relative;
  margin-bottom: 1.5rem;
  padding-bottom: 0.5rem;
  padding-top: 0.5rem;
}

.block-title-13 h1, .block-title-13 h2, .block-title-13 h3, .block-title-13 h4, .block-title-13 h5, .block-title-13 h6 {
  float: left;
  margin-right: 1rem;
}

.block-title-14 {
  position: relative;
  margin-bottom: 1.5rem;
}

.block-title-14 h1 span, .block-title-14 h2 span, .block-title-14 h3 span, .block-title-14 h4 span, .block-title-14 h5 span, .block-title-14 h6 span {
  display: inline-block;
  position: relative;
}

.block-title-15 {
  position: relative;
  margin-bottom: 1.5rem;
}

.block-title-15 h1, .block-title-15 h2, .block-title-15 h3, .block-title-15 h4, .block-title-15 h5, .block-title-15 h6 {
  border-bottom: #dee2e6 2px solid;
}

.center-backline:before {
  margin-top: -.5px;
  margin-bottom: 0;
}

.center-backline-lg:before {
  margin-top: -0.5rem;
}

@media (max-width: 767.98px) {
  .center-backline:before {
    left: 5px;
    right: 5px;
  }
}

.title-box-stripe {
  margin-top: -0.4rem;
}

.stripe-line {
  background: url(../img/assets/stripe.png);
  height: 12px;
  overflow: hidden;
  margin-top: 0;
}

.title-box-dot {
  margin-top: -0.4rem;
}

.dot-line {
  background: url(../img/assets/dot.png);
  height: 12px;
  overflow: hidden;
  margin-top: 0;
}

.title-arrow:before {
  content: '';
  width: 2.5rem;
  height: 2px;
  position: absolute;
  top: 2rem;
  background: #6c63ff;
}

.u-backline:after {
  content: "";
  position: absolute;
  width: 100%;
  height: 2px;
  background-color: #6c63ff;
  margin-top: 1.5rem;
}

.box-title:before {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  border-style: solid;
  border-color: transparent;
  bottom: -.5rem;
  left: 50%;
  border-top-color: #6c63ff;
  border-width: 0.5rem;
  border-bottom: 0;
  margin-left: -10px;
}

.box-title.border-primary:before {
  border-top-color: #6c63ff;
}

.box-title.border-secondary:before {
  border-top-color: #000;
}

.box-title.border-success:before {
  border-top-color: #00b191;
}

.box-title.border-info:before {
  border-top-color: #17a2b8;
}

.box-title.border-warning:before {
  border-top-color: #ffc107;
}

.box-title.border-danger:before {
  border-top-color: #d72924;
}

.box-title.border-light:before {
  border-top-color: #f6f6f6;
}

.box-title.border-dark:before {
  border-top-color: #1b1b1b;
}

.box-title.border-pink:before {
  border-top-color: #d6456a;
}

.box-title.border-purple:before {
  border-top-color: #7952B3;
}

.six-block-title span {
  margin-bottom: -2px;
  display: inline-block;
  position: relative;
}

.nav-block-link, .nav-block-link1, .nav-block-link2, .nav-block-link3, .nav-block-link4, .nav-block-link5, .nav-block-link6, .nav-block-link7, .nav-block-more {
  position: absolute;
  top: 0;
  right: 3.2rem;
  margin: auto 0;
  z-index: 2;
  line-height: 30px;
  text-align: right;
  border: 0;
}

.nav-block-link li, .nav-block-link1 li, .nav-block-link2 li, .nav-block-link3 li, .nav-block-link4 li, .nav-block-link5 li, .nav-block-link6 li, .nav-block-link7 li, .nav-block-more li {
  display: inline-block;
  margin-right: 1.5rem;
}

.nav-block-link li a, .nav-block-link1 li a, .nav-block-link2 li a, .nav-block-link3 li a, .nav-block-link4 li a, .nav-block-link5 li a, .nav-block-link6 li a, .nav-block-link7 li a, .nav-block-more li a {
  color: #1b1b1b;
}

.nav-block-link li a:hover, .nav-block-link1 li a:hover, .nav-block-link2 li a:hover, .nav-block-link3 li a:hover, .nav-block-link4 li a:hover, .nav-block-link5 li a:hover, .nav-block-link6 li a:hover, .nav-block-link7 li a:hover, .nav-block-more li a:hover {
  color: #6c63ff;
}

.nav-block-link li a.nav-tabs .nav-item, .nav-block-link1 li a.nav-tabs .nav-item, .nav-block-link2 li a.nav-tabs .nav-item, .nav-block-link3 li a.nav-tabs .nav-item, .nav-block-link4 li a.nav-tabs .nav-item, .nav-block-link5 li a.nav-tabs .nav-item, .nav-block-link6 li a.nav-tabs .nav-item, .nav-block-link7 li a.nav-tabs .nav-item, .nav-block-more li a.nav-tabs .nav-item {
  margin-bottom: 3px;
}

.nav-block-link li a.nav-link, .nav-block-link1 li a.nav-link, .nav-block-link2 li a.nav-link, .nav-block-link3 li a.nav-link, .nav-block-link4 li a.nav-link, .nav-block-link5 li a.nav-link, .nav-block-link6 li a.nav-link, .nav-block-link7 li a.nav-link, .nav-block-more li a.nav-link {
  display: inline;
  border: 0;
  color: #1b1b1b;
  background-color: transparent;
  padding: 0.5rem 0rem;
}

.nav-block-link li a.nav-link.active, .nav-block-link li a.nav-link:hover, .nav-block-link1 li a.nav-link.active, .nav-block-link1 li a.nav-link:hover, .nav-block-link2 li a.nav-link.active, .nav-block-link2 li a.nav-link:hover, .nav-block-link3 li a.nav-link.active, .nav-block-link3 li a.nav-link:hover, .nav-block-link4 li a.nav-link.active, .nav-block-link4 li a.nav-link:hover, .nav-block-link5 li a.nav-link.active, .nav-block-link5 li a.nav-link:hover, .nav-block-link6 li a.nav-link.active, .nav-block-link6 li a.nav-link:hover, .nav-block-link7 li a.nav-link.active, .nav-block-link7 li a.nav-link:hover, .nav-block-more li a.nav-link.active, .nav-block-more li a.nav-link:hover {
  color: #6c63ff;
  background-color: transparent;
}

.nav-block-link li.active a, .nav-block-link1 li.active a, .nav-block-link2 li.active a, .nav-block-link3 li.active a, .nav-block-link4 li.active a, .nav-block-link5 li.active a, .nav-block-link6 li.active a, .nav-block-link7 li.active a, .nav-block-more li.active a {
  color: #6c63ff;
}

.nav-block-more li {
  margin-right: 1rem;
}

.nav-block-more {
  right: 0;
}

@media (max-width: 767.98px) {
  .nav-block-link, .nav-block-link1, .nav-block-link2, .nav-block-link3, .nav-block-link4, .nav-block-link5, .nav-block-link6, .nav-block-link7 {
    width: 50%;
  }
}

@media (max-width: 991.98px) {
  .nav-block-link, .nav-block-link1, .nav-block-link2, .nav-block-link3, .nav-block-link4, .nav-block-link5, .nav-block-link6, .nav-block-link7 {
    overflow: hidden;
    width: 60%;
    height: 2rem;
  }
  .nav-block-more {
    width: inherit;
  }
}

/*------------------------------------
  News Block
------------------------------------*/
.block-area, .widget {
  position: relative;
  clear: both;
  margin-bottom: 1.5rem;
}

.block-area .btn, .widget .btn {
  margin-bottom: 0.5rem;
}

.sticky {
  margin-bottom: 1.5rem;
}

.first-pt-0 article:first-child {
  padding-top: 0 !important;
}

.block-primary a {
  color: #6c63ff;
}

.block-primary a:hover {
  color: #6c63ff !important;
}

.block-primary a.badge {
  color: #fff !important;
  background-color: #6c63ff;
}

.block-primary a.btn:hover {
  color: #fff !important;
}

.block-primary h1 > a, .block-primary h2 > a, .block-primary h3 > a, .block-primary h4 > a, .block-primary h5 > a, .block-primary h6 > a, .block-primary .h1 > a, .block-primary .h2 > a, .block-primary .h3 > a, .block-primary .h4 > a, .block-primary .h5 > a, .block-primary .h6 > a {
  color: #1b1b1b;
}

.block-primary h1 > a:hover, .block-primary h2 > a:hover, .block-primary h3 > a:hover, .block-primary h4 > a:hover, .block-primary h5 > a:hover, .block-primary h6 > a:hover, .block-primary .h1 > a:hover, .block-primary .h2 > a:hover, .block-primary .h3 > a:hover, .block-primary .h4 > a:hover, .block-primary .h5 > a:hover, .block-primary .h6 > a:hover {
  color: #6c63ff !important;
}

.block-primary .hover-a:hover h1 > a, .block-primary .hover-a:hover h2 > a, .block-primary .hover-a:hover h3 > a, .block-primary .hover-a:hover h4 > a, .block-primary .hover-a:hover h5 > a, .block-primary .hover-a:hover h6 > a, .block-primary .hover-a:hover .h1 > a, .block-primary .hover-a:hover .h2 > a, .block-primary .hover-a:hover .h3 > a, .block-primary .hover-a:hover .h4 > a, .block-primary .hover-a:hover .h5 > a, .block-primary .hover-a:hover .h6 > a {
  color: #6c63ff !important;
}

.block-primary .hover-a:hover h1 > a.text-white, .block-primary .hover-a:hover h2 > a.text-white, .block-primary .hover-a:hover h3 > a.text-white, .block-primary .hover-a:hover h4 > a.text-white, .block-primary .hover-a:hover h5 > a.text-white, .block-primary .hover-a:hover h6 > a.text-white, .block-primary .hover-a:hover .h1 > a.text-white, .block-primary .hover-a:hover .h2 > a.text-white, .block-primary .hover-a:hover .h3 > a.text-white, .block-primary .hover-a:hover .h4 > a.text-white, .block-primary .hover-a:hover .h5 > a.text-white, .block-primary .hover-a:hover .h6 > a.text-white {
  color: #fff !important;
}

.block-primary .sub-card ul li a {
  color: #1b1b1b !important;
}

.block-primary .sub-card ul li a:hover {
  color: #6c63ff !important;
}

.block-secondary a {
  color: #000;
}

.block-secondary a:hover {
  color: #000 !important;
}

.block-secondary a.badge {
  color: #fff !important;
  background-color: #000;
}

.block-secondary a.btn:hover {
  color: #fff !important;
}

.block-secondary h1 > a, .block-secondary h2 > a, .block-secondary h3 > a, .block-secondary h4 > a, .block-secondary h5 > a, .block-secondary h6 > a, .block-secondary .h1 > a, .block-secondary .h2 > a, .block-secondary .h3 > a, .block-secondary .h4 > a, .block-secondary .h5 > a, .block-secondary .h6 > a {
  color: #1b1b1b;
}

.block-secondary h1 > a:hover, .block-secondary h2 > a:hover, .block-secondary h3 > a:hover, .block-secondary h4 > a:hover, .block-secondary h5 > a:hover, .block-secondary h6 > a:hover, .block-secondary .h1 > a:hover, .block-secondary .h2 > a:hover, .block-secondary .h3 > a:hover, .block-secondary .h4 > a:hover, .block-secondary .h5 > a:hover, .block-secondary .h6 > a:hover {
  color: #000 !important;
}

.block-secondary .hover-a:hover h1 > a, .block-secondary .hover-a:hover h2 > a, .block-secondary .hover-a:hover h3 > a, .block-secondary .hover-a:hover h4 > a, .block-secondary .hover-a:hover h5 > a, .block-secondary .hover-a:hover h6 > a, .block-secondary .hover-a:hover .h1 > a, .block-secondary .hover-a:hover .h2 > a, .block-secondary .hover-a:hover .h3 > a, .block-secondary .hover-a:hover .h4 > a, .block-secondary .hover-a:hover .h5 > a, .block-secondary .hover-a:hover .h6 > a {
  color: #000 !important;
}

.block-secondary .hover-a:hover h1 > a.text-white, .block-secondary .hover-a:hover h2 > a.text-white, .block-secondary .hover-a:hover h3 > a.text-white, .block-secondary .hover-a:hover h4 > a.text-white, .block-secondary .hover-a:hover h5 > a.text-white, .block-secondary .hover-a:hover h6 > a.text-white, .block-secondary .hover-a:hover .h1 > a.text-white, .block-secondary .hover-a:hover .h2 > a.text-white, .block-secondary .hover-a:hover .h3 > a.text-white, .block-secondary .hover-a:hover .h4 > a.text-white, .block-secondary .hover-a:hover .h5 > a.text-white, .block-secondary .hover-a:hover .h6 > a.text-white {
  color: #fff !important;
}

.block-secondary .sub-card ul li a {
  color: #1b1b1b !important;
}

.block-secondary .sub-card ul li a:hover {
  color: #000 !important;
}

.block-success a {
  color: #00b191;
}

.block-success a:hover {
  color: #00b191 !important;
}

.block-success a.badge {
  color: #fff !important;
  background-color: #00b191;
}

.block-success a.btn:hover {
  color: #fff !important;
}

.block-success h1 > a, .block-success h2 > a, .block-success h3 > a, .block-success h4 > a, .block-success h5 > a, .block-success h6 > a, .block-success .h1 > a, .block-success .h2 > a, .block-success .h3 > a, .block-success .h4 > a, .block-success .h5 > a, .block-success .h6 > a {
  color: #1b1b1b;
}

.block-success h1 > a:hover, .block-success h2 > a:hover, .block-success h3 > a:hover, .block-success h4 > a:hover, .block-success h5 > a:hover, .block-success h6 > a:hover, .block-success .h1 > a:hover, .block-success .h2 > a:hover, .block-success .h3 > a:hover, .block-success .h4 > a:hover, .block-success .h5 > a:hover, .block-success .h6 > a:hover {
  color: #00b191 !important;
}

.block-success .hover-a:hover h1 > a, .block-success .hover-a:hover h2 > a, .block-success .hover-a:hover h3 > a, .block-success .hover-a:hover h4 > a, .block-success .hover-a:hover h5 > a, .block-success .hover-a:hover h6 > a, .block-success .hover-a:hover .h1 > a, .block-success .hover-a:hover .h2 > a, .block-success .hover-a:hover .h3 > a, .block-success .hover-a:hover .h4 > a, .block-success .hover-a:hover .h5 > a, .block-success .hover-a:hover .h6 > a {
  color: #00b191 !important;
}

.block-success .hover-a:hover h1 > a.text-white, .block-success .hover-a:hover h2 > a.text-white, .block-success .hover-a:hover h3 > a.text-white, .block-success .hover-a:hover h4 > a.text-white, .block-success .hover-a:hover h5 > a.text-white, .block-success .hover-a:hover h6 > a.text-white, .block-success .hover-a:hover .h1 > a.text-white, .block-success .hover-a:hover .h2 > a.text-white, .block-success .hover-a:hover .h3 > a.text-white, .block-success .hover-a:hover .h4 > a.text-white, .block-success .hover-a:hover .h5 > a.text-white, .block-success .hover-a:hover .h6 > a.text-white {
  color: #fff !important;
}

.block-success .sub-card ul li a {
  color: #1b1b1b !important;
}

.block-success .sub-card ul li a:hover {
  color: #00b191 !important;
}

.block-info a {
  color: #17a2b8;
}

.block-info a:hover {
  color: #17a2b8 !important;
}

.block-info a.badge {
  color: #fff !important;
  background-color: #17a2b8;
}

.block-info a.btn:hover {
  color: #fff !important;
}

.block-info h1 > a, .block-info h2 > a, .block-info h3 > a, .block-info h4 > a, .block-info h5 > a, .block-info h6 > a, .block-info .h1 > a, .block-info .h2 > a, .block-info .h3 > a, .block-info .h4 > a, .block-info .h5 > a, .block-info .h6 > a {
  color: #1b1b1b;
}

.block-info h1 > a:hover, .block-info h2 > a:hover, .block-info h3 > a:hover, .block-info h4 > a:hover, .block-info h5 > a:hover, .block-info h6 > a:hover, .block-info .h1 > a:hover, .block-info .h2 > a:hover, .block-info .h3 > a:hover, .block-info .h4 > a:hover, .block-info .h5 > a:hover, .block-info .h6 > a:hover {
  color: #17a2b8 !important;
}

.block-info .hover-a:hover h1 > a, .block-info .hover-a:hover h2 > a, .block-info .hover-a:hover h3 > a, .block-info .hover-a:hover h4 > a, .block-info .hover-a:hover h5 > a, .block-info .hover-a:hover h6 > a, .block-info .hover-a:hover .h1 > a, .block-info .hover-a:hover .h2 > a, .block-info .hover-a:hover .h3 > a, .block-info .hover-a:hover .h4 > a, .block-info .hover-a:hover .h5 > a, .block-info .hover-a:hover .h6 > a {
  color: #17a2b8 !important;
}

.block-info .hover-a:hover h1 > a.text-white, .block-info .hover-a:hover h2 > a.text-white, .block-info .hover-a:hover h3 > a.text-white, .block-info .hover-a:hover h4 > a.text-white, .block-info .hover-a:hover h5 > a.text-white, .block-info .hover-a:hover h6 > a.text-white, .block-info .hover-a:hover .h1 > a.text-white, .block-info .hover-a:hover .h2 > a.text-white, .block-info .hover-a:hover .h3 > a.text-white, .block-info .hover-a:hover .h4 > a.text-white, .block-info .hover-a:hover .h5 > a.text-white, .block-info .hover-a:hover .h6 > a.text-white {
  color: #fff !important;
}

.block-info .sub-card ul li a {
  color: #1b1b1b !important;
}

.block-info .sub-card ul li a:hover {
  color: #17a2b8 !important;
}

.block-warning a {
  color: #ffc107;
}

.block-warning a:hover {
  color: #ffc107 !important;
}

.block-warning a.badge {
  color: #fff !important;
  background-color: #ffc107;
}

.block-warning a.btn:hover {
  color: #fff !important;
}

.block-warning h1 > a, .block-warning h2 > a, .block-warning h3 > a, .block-warning h4 > a, .block-warning h5 > a, .block-warning h6 > a, .block-warning .h1 > a, .block-warning .h2 > a, .block-warning .h3 > a, .block-warning .h4 > a, .block-warning .h5 > a, .block-warning .h6 > a {
  color: #1b1b1b;
}

.block-warning h1 > a:hover, .block-warning h2 > a:hover, .block-warning h3 > a:hover, .block-warning h4 > a:hover, .block-warning h5 > a:hover, .block-warning h6 > a:hover, .block-warning .h1 > a:hover, .block-warning .h2 > a:hover, .block-warning .h3 > a:hover, .block-warning .h4 > a:hover, .block-warning .h5 > a:hover, .block-warning .h6 > a:hover {
  color: #ffc107 !important;
}

.block-warning .hover-a:hover h1 > a, .block-warning .hover-a:hover h2 > a, .block-warning .hover-a:hover h3 > a, .block-warning .hover-a:hover h4 > a, .block-warning .hover-a:hover h5 > a, .block-warning .hover-a:hover h6 > a, .block-warning .hover-a:hover .h1 > a, .block-warning .hover-a:hover .h2 > a, .block-warning .hover-a:hover .h3 > a, .block-warning .hover-a:hover .h4 > a, .block-warning .hover-a:hover .h5 > a, .block-warning .hover-a:hover .h6 > a {
  color: #ffc107 !important;
}

.block-warning .hover-a:hover h1 > a.text-white, .block-warning .hover-a:hover h2 > a.text-white, .block-warning .hover-a:hover h3 > a.text-white, .block-warning .hover-a:hover h4 > a.text-white, .block-warning .hover-a:hover h5 > a.text-white, .block-warning .hover-a:hover h6 > a.text-white, .block-warning .hover-a:hover .h1 > a.text-white, .block-warning .hover-a:hover .h2 > a.text-white, .block-warning .hover-a:hover .h3 > a.text-white, .block-warning .hover-a:hover .h4 > a.text-white, .block-warning .hover-a:hover .h5 > a.text-white, .block-warning .hover-a:hover .h6 > a.text-white {
  color: #fff !important;
}

.block-warning .sub-card ul li a {
  color: #1b1b1b !important;
}

.block-warning .sub-card ul li a:hover {
  color: #ffc107 !important;
}

.block-danger a {
  color: #d72924;
}

.block-danger a:hover {
  color: #d72924 !important;
}

.block-danger a.badge {
  color: #fff !important;
  background-color: #d72924;
}

.block-danger a.btn:hover {
  color: #fff !important;
}

.block-danger h1 > a, .block-danger h2 > a, .block-danger h3 > a, .block-danger h4 > a, .block-danger h5 > a, .block-danger h6 > a, .block-danger .h1 > a, .block-danger .h2 > a, .block-danger .h3 > a, .block-danger .h4 > a, .block-danger .h5 > a, .block-danger .h6 > a {
  color: #1b1b1b;
}

.block-danger h1 > a:hover, .block-danger h2 > a:hover, .block-danger h3 > a:hover, .block-danger h4 > a:hover, .block-danger h5 > a:hover, .block-danger h6 > a:hover, .block-danger .h1 > a:hover, .block-danger .h2 > a:hover, .block-danger .h3 > a:hover, .block-danger .h4 > a:hover, .block-danger .h5 > a:hover, .block-danger .h6 > a:hover {
  color: #d72924 !important;
}

.block-danger .hover-a:hover h1 > a, .block-danger .hover-a:hover h2 > a, .block-danger .hover-a:hover h3 > a, .block-danger .hover-a:hover h4 > a, .block-danger .hover-a:hover h5 > a, .block-danger .hover-a:hover h6 > a, .block-danger .hover-a:hover .h1 > a, .block-danger .hover-a:hover .h2 > a, .block-danger .hover-a:hover .h3 > a, .block-danger .hover-a:hover .h4 > a, .block-danger .hover-a:hover .h5 > a, .block-danger .hover-a:hover .h6 > a {
  color: #d72924 !important;
}

.block-danger .hover-a:hover h1 > a.text-white, .block-danger .hover-a:hover h2 > a.text-white, .block-danger .hover-a:hover h3 > a.text-white, .block-danger .hover-a:hover h4 > a.text-white, .block-danger .hover-a:hover h5 > a.text-white, .block-danger .hover-a:hover h6 > a.text-white, .block-danger .hover-a:hover .h1 > a.text-white, .block-danger .hover-a:hover .h2 > a.text-white, .block-danger .hover-a:hover .h3 > a.text-white, .block-danger .hover-a:hover .h4 > a.text-white, .block-danger .hover-a:hover .h5 > a.text-white, .block-danger .hover-a:hover .h6 > a.text-white {
  color: #fff !important;
}

.block-danger .sub-card ul li a {
  color: #1b1b1b !important;
}

.block-danger .sub-card ul li a:hover {
  color: #d72924 !important;
}

.block-light a {
  color: #f6f6f6;
}

.block-light a:hover {
  color: #f6f6f6 !important;
}

.block-light a.badge {
  color: #fff !important;
  background-color: #f6f6f6;
}

.block-light a.btn:hover {
  color: #fff !important;
}

.block-light h1 > a, .block-light h2 > a, .block-light h3 > a, .block-light h4 > a, .block-light h5 > a, .block-light h6 > a, .block-light .h1 > a, .block-light .h2 > a, .block-light .h3 > a, .block-light .h4 > a, .block-light .h5 > a, .block-light .h6 > a {
  color: #1b1b1b;
}

.block-light h1 > a:hover, .block-light h2 > a:hover, .block-light h3 > a:hover, .block-light h4 > a:hover, .block-light h5 > a:hover, .block-light h6 > a:hover, .block-light .h1 > a:hover, .block-light .h2 > a:hover, .block-light .h3 > a:hover, .block-light .h4 > a:hover, .block-light .h5 > a:hover, .block-light .h6 > a:hover {
  color: #f6f6f6 !important;
}

.block-light .hover-a:hover h1 > a, .block-light .hover-a:hover h2 > a, .block-light .hover-a:hover h3 > a, .block-light .hover-a:hover h4 > a, .block-light .hover-a:hover h5 > a, .block-light .hover-a:hover h6 > a, .block-light .hover-a:hover .h1 > a, .block-light .hover-a:hover .h2 > a, .block-light .hover-a:hover .h3 > a, .block-light .hover-a:hover .h4 > a, .block-light .hover-a:hover .h5 > a, .block-light .hover-a:hover .h6 > a {
  color: #f6f6f6 !important;
}

.block-light .hover-a:hover h1 > a.text-white, .block-light .hover-a:hover h2 > a.text-white, .block-light .hover-a:hover h3 > a.text-white, .block-light .hover-a:hover h4 > a.text-white, .block-light .hover-a:hover h5 > a.text-white, .block-light .hover-a:hover h6 > a.text-white, .block-light .hover-a:hover .h1 > a.text-white, .block-light .hover-a:hover .h2 > a.text-white, .block-light .hover-a:hover .h3 > a.text-white, .block-light .hover-a:hover .h4 > a.text-white, .block-light .hover-a:hover .h5 > a.text-white, .block-light .hover-a:hover .h6 > a.text-white {
  color: #fff !important;
}

.block-light .sub-card ul li a {
  color: #1b1b1b !important;
}

.block-light .sub-card ul li a:hover {
  color: #f6f6f6 !important;
}

.block-dark a {
  color: #1b1b1b;
}

.block-dark a:hover {
  color: #1b1b1b !important;
}

.block-dark a.badge {
  color: #fff !important;
  background-color: #1b1b1b;
}

.block-dark a.btn:hover {
  color: #fff !important;
}

.block-dark h1 > a, .block-dark h2 > a, .block-dark h3 > a, .block-dark h4 > a, .block-dark h5 > a, .block-dark h6 > a, .block-dark .h1 > a, .block-dark .h2 > a, .block-dark .h3 > a, .block-dark .h4 > a, .block-dark .h5 > a, .block-dark .h6 > a {
  color: #1b1b1b;
}

.block-dark h1 > a:hover, .block-dark h2 > a:hover, .block-dark h3 > a:hover, .block-dark h4 > a:hover, .block-dark h5 > a:hover, .block-dark h6 > a:hover, .block-dark .h1 > a:hover, .block-dark .h2 > a:hover, .block-dark .h3 > a:hover, .block-dark .h4 > a:hover, .block-dark .h5 > a:hover, .block-dark .h6 > a:hover {
  color: #1b1b1b !important;
}

.block-dark .hover-a:hover h1 > a, .block-dark .hover-a:hover h2 > a, .block-dark .hover-a:hover h3 > a, .block-dark .hover-a:hover h4 > a, .block-dark .hover-a:hover h5 > a, .block-dark .hover-a:hover h6 > a, .block-dark .hover-a:hover .h1 > a, .block-dark .hover-a:hover .h2 > a, .block-dark .hover-a:hover .h3 > a, .block-dark .hover-a:hover .h4 > a, .block-dark .hover-a:hover .h5 > a, .block-dark .hover-a:hover .h6 > a {
  color: #1b1b1b !important;
}

.block-dark .hover-a:hover h1 > a.text-white, .block-dark .hover-a:hover h2 > a.text-white, .block-dark .hover-a:hover h3 > a.text-white, .block-dark .hover-a:hover h4 > a.text-white, .block-dark .hover-a:hover h5 > a.text-white, .block-dark .hover-a:hover h6 > a.text-white, .block-dark .hover-a:hover .h1 > a.text-white, .block-dark .hover-a:hover .h2 > a.text-white, .block-dark .hover-a:hover .h3 > a.text-white, .block-dark .hover-a:hover .h4 > a.text-white, .block-dark .hover-a:hover .h5 > a.text-white, .block-dark .hover-a:hover .h6 > a.text-white {
  color: #fff !important;
}

.block-dark .sub-card ul li a {
  color: #1b1b1b !important;
}

.block-dark .sub-card ul li a:hover {
  color: #1b1b1b !important;
}

.block-pink a {
  color: #d6456a;
}

.block-pink a:hover {
  color: #d6456a !important;
}

.block-pink a.badge {
  color: #fff !important;
  background-color: #d6456a;
}

.block-pink a.btn:hover {
  color: #fff !important;
}

.block-pink h1 > a, .block-pink h2 > a, .block-pink h3 > a, .block-pink h4 > a, .block-pink h5 > a, .block-pink h6 > a, .block-pink .h1 > a, .block-pink .h2 > a, .block-pink .h3 > a, .block-pink .h4 > a, .block-pink .h5 > a, .block-pink .h6 > a {
  color: #1b1b1b;
}

.block-pink h1 > a:hover, .block-pink h2 > a:hover, .block-pink h3 > a:hover, .block-pink h4 > a:hover, .block-pink h5 > a:hover, .block-pink h6 > a:hover, .block-pink .h1 > a:hover, .block-pink .h2 > a:hover, .block-pink .h3 > a:hover, .block-pink .h4 > a:hover, .block-pink .h5 > a:hover, .block-pink .h6 > a:hover {
  color: #d6456a !important;
}

.block-pink .hover-a:hover h1 > a, .block-pink .hover-a:hover h2 > a, .block-pink .hover-a:hover h3 > a, .block-pink .hover-a:hover h4 > a, .block-pink .hover-a:hover h5 > a, .block-pink .hover-a:hover h6 > a, .block-pink .hover-a:hover .h1 > a, .block-pink .hover-a:hover .h2 > a, .block-pink .hover-a:hover .h3 > a, .block-pink .hover-a:hover .h4 > a, .block-pink .hover-a:hover .h5 > a, .block-pink .hover-a:hover .h6 > a {
  color: #d6456a !important;
}

.block-pink .hover-a:hover h1 > a.text-white, .block-pink .hover-a:hover h2 > a.text-white, .block-pink .hover-a:hover h3 > a.text-white, .block-pink .hover-a:hover h4 > a.text-white, .block-pink .hover-a:hover h5 > a.text-white, .block-pink .hover-a:hover h6 > a.text-white, .block-pink .hover-a:hover .h1 > a.text-white, .block-pink .hover-a:hover .h2 > a.text-white, .block-pink .hover-a:hover .h3 > a.text-white, .block-pink .hover-a:hover .h4 > a.text-white, .block-pink .hover-a:hover .h5 > a.text-white, .block-pink .hover-a:hover .h6 > a.text-white {
  color: #fff !important;
}

.block-pink .sub-card ul li a {
  color: #1b1b1b !important;
}

.block-pink .sub-card ul li a:hover {
  color: #d6456a !important;
}

.block-purple a {
  color: #7952B3;
}

.block-purple a:hover {
  color: #7952B3 !important;
}

.block-purple a.badge {
  color: #fff !important;
  background-color: #7952B3;
}

.block-purple a.btn:hover {
  color: #fff !important;
}

.block-purple h1 > a, .block-purple h2 > a, .block-purple h3 > a, .block-purple h4 > a, .block-purple h5 > a, .block-purple h6 > a, .block-purple .h1 > a, .block-purple .h2 > a, .block-purple .h3 > a, .block-purple .h4 > a, .block-purple .h5 > a, .block-purple .h6 > a {
  color: #1b1b1b;
}

.block-purple h1 > a:hover, .block-purple h2 > a:hover, .block-purple h3 > a:hover, .block-purple h4 > a:hover, .block-purple h5 > a:hover, .block-purple h6 > a:hover, .block-purple .h1 > a:hover, .block-purple .h2 > a:hover, .block-purple .h3 > a:hover, .block-purple .h4 > a:hover, .block-purple .h5 > a:hover, .block-purple .h6 > a:hover {
  color: #7952B3 !important;
}

.block-purple .hover-a:hover h1 > a, .block-purple .hover-a:hover h2 > a, .block-purple .hover-a:hover h3 > a, .block-purple .hover-a:hover h4 > a, .block-purple .hover-a:hover h5 > a, .block-purple .hover-a:hover h6 > a, .block-purple .hover-a:hover .h1 > a, .block-purple .hover-a:hover .h2 > a, .block-purple .hover-a:hover .h3 > a, .block-purple .hover-a:hover .h4 > a, .block-purple .hover-a:hover .h5 > a, .block-purple .hover-a:hover .h6 > a {
  color: #7952B3 !important;
}

.block-purple .hover-a:hover h1 > a.text-white, .block-purple .hover-a:hover h2 > a.text-white, .block-purple .hover-a:hover h3 > a.text-white, .block-purple .hover-a:hover h4 > a.text-white, .block-purple .hover-a:hover h5 > a.text-white, .block-purple .hover-a:hover h6 > a.text-white, .block-purple .hover-a:hover .h1 > a.text-white, .block-purple .hover-a:hover .h2 > a.text-white, .block-purple .hover-a:hover .h3 > a.text-white, .block-purple .hover-a:hover .h4 > a.text-white, .block-purple .hover-a:hover .h5 > a.text-white, .block-purple .hover-a:hover .h6 > a.text-white {
  color: #fff !important;
}

.block-purple .sub-card ul li a {
  color: #1b1b1b !important;
}

.block-purple .sub-card ul li a:hover {
  color: #7952B3 !important;
}

/*------------------------------------
	Breaking news
------------------------------------*/
.navigation-box {
  top: 0.8rem;
  right: 0.8rem;
  width: 2rem;
  position: absolute;
}

.aribudin {
  white-space: nowrap;
  width: 100%;
  overflow: hidden;
  border-image-source: initial;
  border-image-slice: initial;
  border-image-width: initial;
  border-image-outset: initial;
  border-image-repeat: initial;
  text-overflow: ellipsis;
}

.page-link.disabled {
  cursor: not-allowed;
}

@media (max-width: 767.98px) {
  .breaking-box .carousel-item {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }
}

@media (min-width: 768px) {
  .breaking-box .carousel-item {
    line-height: 2.25rem;
  }
}

/*------------------------------------
	Back to top
------------------------------------*/
.back-top {
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.3s 0s, visibility 0s 0.3s;
}

.back-top.backtop-is-visible {
  transition: opacity 0.3s 0s, visibility 0s 0s;
  z-index: 99;
  visibility: visible;
  opacity: 1;
}

.back-top.backtop-fade-out {
  transition: opacity 0.3s 0s, visibility 0s 0s;
  opacity: 0.5;
}

.no-touch .back-top:hover {
  transition: opacity 0.3s 0s, visibility 0s 0s;
  background-color: #6c63ff;
  opacity: 1;
}

/*------------------------------------
 Mobile menu
------------------------------------*/
.sidenav-left-open .wrapper:before, .sidenav-right-open .wrapper:before {
  content: "";
  position: fixed;
  background: rgba(0, 0, 0, 0.6);
  z-index: 25;
  width: 100%;
  height: 100%;
}

.sidenav-left-open .back-menu, .sidenav-right-open .back-menu {
  position: fixed;
  visibility: visible;
  opacity: 1;
  text-decoration: none;
  top: 0;
  z-index: 50;
  color: #fff;
  padding: 1rem;
  height: 100%;
  cursor: pointer;
}

.body {
  position: relative;
  left: 0;
  overflow-x: hidden;
}

.sidenav-left-open {
  position: fixed;
  width: 100%;
}

.sidenav-left-open #showLeftPush {
  opacity: 0;
}

.sidenav-left-open:before {
  right: 0;
}

.sidenav-left-open .back-menu {
  right: 0;
  left: 17.5rem;
}

.sidenav-left-open .back-menu span {
  display: block;
}

.sidenav-right-open {
  position: fixed;
  width: 100%;
}

.sidenav-right-open #showRightPush {
  opacity: 0;
}

.sidenav-right-open:before {
  left: 0;
}

.sidenav-right-open .back-menu {
  left: 0;
  right: 17.5rem;
}

.sidenav-right-open .back-menu span {
  display: block;
  margin-left: auto;
}

.menu-mobile {
  transition: all 0.5s ease;
  position: fixed;
  top: 0;
  z-index: 100;
  padding: 1rem;
  width: 17.5rem;
  height: 100%;
  overflow: auto;
}

.menu-mobile a {
  display: block;
  background: transparent;
  cursor: pointer;
  border: 0;
  font-size: 0.875rem;
  font-family: "Roboto", sans-serif;
  letter-spacing: 1px;
  padding: 0.75rem 0;
  font-weight: 500;
  text-decoration: none;
  position: relative;
  z-index: 2000;
}

.menu-mobile .mobile-copyright a {
  display: inline-block;
  font-size: 1rem;
  font-weight: 400;
  padding: inherit;
}

.admin-bar .menu-mobile {
  top: 32px;
}

.push {
  transition: all 0.5s ease;
}

.push-left {
  left: -17.5rem;
}

.push-left.pushleft-open {
  left: 0;
}

.back-menu {
  opacity: 0;
  visibility: hidden;
}

.back-menu span {
  display: none;
}

.back-menu .open {
  padding: 1rem;
}

.push-right {
  right: -17.5rem;
}

.push-right.pushright-open {
  right: 0;
}

.side-sign {
  text-align: center;
}

.side-sign a {
  display: inline;
}

.boxed.sidenav-left-open .wrapper, .boxed.sidenav-right-open .wrapper {
  width: 100%;
}

.framed.sidenav-left-open .wrapper, .framed.sidenav-right-open .wrapper {
  width: 100%;
}

.social-icons {
  margin-top: 1rem;
  text-align: center;
}

.social-icons a {
  display: inline;
  max-width: 20%;
}

.sidenav-menu {
  margin-bottom: 1rem;
}

.sidenav-menu a.caret-collapse:after {
  font-family: "Font Awesome 5 Free";
  font-weight: 800;
  font-size: inherit;
  text-rendering: auto;
  float: right;
  content: "\f054";
  transform: rotate(0deg);
  transition: all linear 0.25s;
}

.sidenav-menu a[aria-expanded="true"].caret-collapse:after {
  transform: rotate(90deg);
}

.sidenav-menu .mega article.while-post {
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
  max-width: 100%;
  width: 100%;
}

.sidenav-menu .mega article.while-post .image-wrapper > a {
  padding-top: 0;
}

.sidenav-menu .mega article.while-post .card-body .card-text {
  display: none;
}

.sidenav-menu .navbar-header, .sidenav-menu .navbar-nav, .sidenav-menu .navbar-nav > li {
  float: none;
}

.sidenav-menu .navbar-nav {
  width: 100%;
}

.sidenav-menu .navbar {
  padding: 0;
}

.sidenav-menu .navbar-nav .dropdown-toggle:after {
  content: "\f105";
  float: right;
  margin-right: 0 !important;
}

.sidenav-menu .dropdown-menu .menu-item-has-children > a:after {
  right: 0;
}

.sidenav-menu .dropdown a.show {
  color: #6c63ff;
}

.sidenav-menu .dropdown a.show:after {
  content: "\f107";
}

.sidenav-menu li.show > a.dropdown-toggle {
  color: #6c63ff;
}

.sidenav-menu li.show > a.dropdown-toggle:after {
  content: "\f107" !important;
}

.sidenav-menu .dropdown-item:hover, .sidenav-menu .dropdown-item:focus, .sidenav-menu .dropdown-item:active {
  background: transparent;
  color: #6c63ff;
}

.sidenav-menu .navbar-collapse {
  padding-left: 0;
  padding-right: 0;
}

.sidenav-menu .dropdown-menu {
  padding-top: 0;
  padding-left: 0.875rem;
  border: 0;
  border-top: #dee2e6 1px solid !important;
  box-shadow: none;
  position: static;
  float: none;
  min-width: 0;
}

.sidenav-menu .dropdown-menu.show {
  border-left-width: 0 !important;
}

.sidenav-menu .dropdown-submenu .dropdown-menu {
  border-radius: 0;
  box-shadow: none;
  border-left: 0;
  border-right: 0;
}

.sidenav-menu .dropdown-menu > li > a {
  white-space: normal;
}

.logo-sidenav {
  text-align: center;
}

.logo-sidenav a::before {
  content: "";
  width: 5rem;
  height: 1px;
  background: #000;
  position: absolute;
  bottom: 0;
  left: 50%;
  margin-left: -2.5rem;
}

.list-group-level2 {
  padding-left: 1rem;
  border-top: #dee2e6 1px solid;
}

.list-group-level1 {
  padding-left: 0.5rem;
  border-top: #dee2e6 1px solid;
}

/*------------------------------------
  Post page
------------------------------------*/
.post-content {
  line-height: 1.75;
  font-size: 1rem;
  margin-bottom: 1.5rem;
  -webkit-font-smoothing: antialiased;
}

.post-content h1, .post-content h2, .post-content h3, .post-content h4, .post-content h5, .post-content h6 {
  margin-bottom: 1rem;
}

.post-content p {
  color: #666666;
}

.post-content .align-left, .post-content img.align-left, .post-content .alignleft {
  float: left;
  margin-right: 1.2rem;
  margin-top: 4px;
}

.post-content .align-right, .post-content img.align-right, .post-content .alignright {
  float: right;
  margin-left: 1.2rem;
  margin-top: 4px;
}

.post-content .align-center, .post-content img.align-center, .post-content .aligncenter {
  text-align: center;
}

.post-content .align-center figcaption, .post-content img.align-center figcaption, .post-content .aligncenter figcaption {
  text-align: center;
}

.post-content ul, .post-content ol {
  color: #666666;
}

.post-content ul li, .post-content ol li {
  margin-left: 0;
  line-height: 24px;
}

.post-content img {
  max-width: 100%;
}

.post-content .wp-block-embed {
  position: relative;
  padding-bottom: 56.25%;
  padding-top: 30px;
  height: 0;
  overflow: auto;
}

.post-content .wp-block-embed.is-type-video {
  overflow: hidden;
}

.type-page {
  margin-bottom: 3rem;
}

.post-content figure {
  margin-bottom: 1rem;
}

.post-content figure .b-lazy {
  width: inherit;
}

.post-content figure figcaption {
  font-family: Verdana,sans-serif;
  text-align: left;
  font-size: 90%;
  font-style: italic;
  font-weight: 400;
  line-height: 1rem;
  color: #666666;
  padding: 5px 0;
}

.post-title {
  letter-spacing: -.1px;
  font-weight: 700;
}

.post-title h1 {
  font-weight: 500;
}

.before-arrow li {
  list-style: none;
  margin-left: 0 !important;
}

.before-arrow li:before {
  content: "\f105";
  font-family: "Font Awesome 5 Free";
  font-weight: 800;
  float: left;
  margin-right: 0.8rem;
  font-size: 0.8rem;
}

.children-ul {
  padding-left: 2rem;
}

.ql-editor {
  max-height: 40rem;
}

.u-blockquote, .wp-block-quote {
  border-left: #6c63ff 3px solid;
  background: #f6f6f6;
  padding: 1rem;
}

.u-blockquote::before, .wp-block-quote::before {
  content: "\f10d";
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  display: inline-block;
  position: absolute;
  color: #e9ecef;
  font-size: 2rem;
  line-height: 1;
  font-style: normal;
}

.u-blockquote p, .u-blockquote footer, .wp-block-quote p, .wp-block-quote footer {
  margin-left: 3.5rem;
}

.wp-block-quote {
  margin-bottom: 1rem;
  font-size: 1.25rem;
}

.wp-block-quote footer {
  margin-left: 0;
}

.wp-block-quote cite {
  margin-left: 3.5rem;
  display: block;
  font-size: 80%;
  color: #6c757d;
}

.post-page blockquote {
  border-left: #6c63ff 3px solid;
  background: #f6f6f6;
  padding: 1rem;
}

.post-page blockquote::before {
  content: "\f10d";
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  display: inline-block;
  position: absolute;
  color: #e9ecef;
  font-size: 2rem;
  line-height: 1;
  font-style: normal;
}

.post-page blockquote p, .post-page blockquote footer {
  margin-left: 3.5rem;
}

.post-page .entry-title {
  margin-bottom: 1.5rem;
}

.post-page strong {
  color: #1b1b1b;
}

.dropcaps {
  float: left;
  display: block;
  margin: 0.7rem 0.7rem 0 0;
  padding: 2px 10px 5px;
  font-size: 3.5rem;
  line-height: 3.5rem;
  text-align: center;
  text-transform: uppercase;
  min-width: 3.5rem;
  min-height: 3.5rem;
}

.dropcaps.rounded-circle {
  font-size: 2.75rem;
  min-width: 4rem;
}

.dropcaps-one {
  color: #1b1b1b;
  font-size: 5rem;
  padding: 0 !important;
}

.next-post-right {
  text-align: right;
}

.prev-post-left {
  text-align: left;
}

.prev-post-left a, .next-post-right a {
  color: #1b1b1b;
  font-size: 1rem;
  font-weight: 500;
}

.prev-post-left a:hover, .next-post-right a:hover {
  color: #6c63ff;
}

.follow-us li {
  display: inline-block;
}

.post-type-icon {
  position: absolute;
  text-align: center;
  width: 2.5rem;
  height: 2.5rem;
  line-height: 32px;
  left: 50%;
  top: 50%;
  margin: -15px 0 0 -15px;
  font-size: 14px;
  transition: all 0.25s;
  -webkit-transition: all 0.25s;
  -moz-transition: all 0.25s;
}

@media (max-width: 991.98px) {
  .image-single-wrapper {
    min-height: 9rem;
  }
  .post-content .video-full {
    padding-bottom: 56% !important;
  }
}

@media (max-width: 767.98px) {
  .post-content .align-left, .post-content img.align-left, .post-content .alignleft {
    max-width: 15rem;
  }
  .post-content .align-right, .post-content img.align-right, .post-content .alignright {
    max-width: 15rem;
  }
  .prev-post-left {
    text-align: center;
    margin-bottom: 1rem;
  }
  .next-post-right {
    text-align: center;
  }
}

@media (max-width: 575.98px) {
  .post-content .align-left, .post-content img.align-left, .post-content .alignleft, .post-content .align-right, .post-content img.align-right, .post-content .alignright {
    float: inherit;
    text-align: center;
    max-width: 100%;
    margin-right: auto;
    margin-left: auto;
  }
  .post-content .align-left figcaption, .post-content img.align-left figcaption, .post-content .alignleft figcaption, .post-content .align-right figcaption, .post-content img.align-right figcaption, .post-content .alignright figcaption {
    text-align: center;
  }
  .author-box {
    display: inherit;
    text-align: center;
  }
}

/*------------------------------------
  Suggestion post
------------------------------------*/
.suggestion-box {
  position: fixed;
  z-index: 999;
  bottom: 4rem;
  right: -18.3rem;
  width: 18.3rem;
  border: #dee2e6 1px solid;
  border-top: #6c63ff 4px solid;
  padding: 10px 20px 20px;
  -webkit-transition: all .5s;
  -moz-transition: all .5s;
  -o-transition: all .5s;
  transition: all .5s;
}

.suggestion-box.show {
  right: 0;
}

.suggestion-box.close {
  display: none;
}

.close-suggestion {
  position: absolute;
  left: 0;
  top: 0;
  padding: 1px 8px 3px;
  font-size: 1.2rem;
  cursor: pointer;
}

/*------------------------------------
	Comment
------------------------------------*/
.comment-list li {
  list-style: none;
  margin-top: 2rem;
}

.comment-list li .user-comment {
  border-bottom: #dee2e6 1px dashed;
  padding-bottom: 1rem;
}

.comment-list li .user-comment .comment-author .avatar {
  float: left;
  margin-right: 1rem;
}

.comment-list li .user-comment .title-comments .authors {
  font-weight: 700;
}

.comment-list li .user-comment .comment-content p {
  margin-bottom: 0;
}

.comment-list li .sub-comment {
  margin-left: 6rem;
}

.comment-list li .sub-comment .sub-comment {
  margin-left: 0;
}

.comment-awaiting-moderation {
  background: #ffc107;
}

@media (min-width: 576px) {
  .comment-list li .sub-comment .sub-comment {
    margin-left: 6rem;
  }
  .comment-list .title-comments .dates {
    float: right;
  }
}

.recentcomments a {
  font-style: italic;
  font-family: "Roboto", Helvetica, sans-serif !important;
  font-size: 0.875rem;
}

.recentcomments .comment-author-link {
  font-style: italic;
  font-family: "Roboto", Helvetica, sans-serif !important;
  font-size: 0.875rem;
}

.recentcomments .comment-author-link a.url {
  color: #6c63ff !important;
}

/*------------------------------------
	Archive Rotate
------------------------------------*/
.rotate-down {
  -ms-transform: rotate(90deg);
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
}

.collapsed .rotate-down {
  -ms-transform: rotate(0deg);
  -webkit-transform: rotate(0deg);
  transform: rotate(0deg);
}

/*------------------------------------
  Ratings
------------------------------------*/
.star-rating, .back-stars, .back-stars-light, .front-stars {
  display: flex;
}

.star-rating {
  font-size: 0.8rem;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

.back-stars {
  color: #1b1b1b;
  position: relative;
}

.back-stars-light {
  color: #f6f6f6;
  position: relative;
}

.front-stars {
  color: #ffc107;
  overflow: hidden;
  position: absolute;
  top: 0;
}

/*------------------------------------
	Tags
------------------------------------*/
.post-content .tags-list li {
  padding: 0rem 0.5rem;
  display: inline-block;
  border: #dee2e6 1px solid;
  margin-bottom: 0.25rem;
  margin-left: inherit;
  line-height: inherit;
}

.post-content .tags-list li:hover {
  background-color: #6c63ff;
}

.post-content .tags-list li:hover a {
  color: #fff;
}

.post-content .tags-list li:first-child {
  padding-left: 0;
  font-family: "Roboto", sans-serif;
  background-color: transparent;
  border-color: transparent !important;
  font-weight: 500;
}

.post-content .tags-list li:first-child span {
  color: #1b1b1b;
}

.tags-links a {
  padding: 0rem 0.5rem;
  display: inline-block;
  border: #dee2e6 1px solid;
  margin-right: 0.25rem;
  margin-bottom: 0.25rem;
  margin-left: inherit;
  line-height: inherit;
}

.tags-links a:hover {
  background-color: #6c63ff;
  color: #fff;
}

.tags-list-cat li {
  padding: 0rem 0.5rem;
  display: inline-block;
  border: #dee2e6 1px solid;
  margin-bottom: 0.25rem;
}

.tags-list-cat li:hover {
  background-color: #6c63ff;
}

.tags-list-cat li:hover a {
  color: #fff;
}

.tagcloud a:before {
  content: "#";
}

/*------------------------------------
  Messages
------------------------------------*/
.unread {
  cursor: pointer;
  background-color: #f6f6f6;
}

.messages-box {
  max-height: 28rem;
  overflow: auto;
}

.online-circle {
  border-radius: 5rem;
  width: 5rem;
  height: 5rem;
}

.messages-title {
  float: right;
  margin: 0px 5px;
}

.message-img {
  float: right;
  margin: 0px 5px;
}

.message-header {
  text-align: right;
  width: 100%;
  margin-bottom: 0.5rem;
}

.text-editor {
  min-height: 18rem;
}

.messages-list li.messages-you .messages-title {
  float: left;
}

.messages-list li.messages-you .message-img {
  float: left;
}

.messages-list li.messages-you p {
  float: left;
  text-align: left;
}

.messages-list li.messages-you .message-header {
  text-align: left;
}

.messages-list li p {
  max-width: 60%;
  padding: 15px;
  border: #dee2e6 1px solid;
}

.messages-list li.messages-me p {
  float: right;
}

.ql-editor p {
  font-size: 1rem;
}

/*------------------------------------
  Owl Carousel v2.3.4
------------------------------------*/
.owl-carousel {
  display: none;
  width: 100%;
  -webkit-tap-highlight-color: transparent;
  position: relative;
  z-index: 1;
}

.owl-carousel .owl-stage {
  position: relative;
  -ms-touch-action: pan-Y;
  touch-action: manipulation;
}

.owl-carousel .owl-stage:after {
  content: ".";
  display: block;
  clear: both;
  visibility: hidden;
  line-height: 0;
  height: 0;
}

.owl-carousel .owl-stage-outer {
  position: relative;
  overflow: hidden;
}

.owl-carousel .owl-item {
  position: relative;
  min-height: 1px;
  float: left;
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
}

.owl-carousel .owl-item img {
  display: block;
  width: 100%;
}

.owl-carousel .owl-nav.disabled, .owl-carousel .owl-dots.disabled {
  display: none;
}

.owl-carousel .owl-nav .owl-prev, .owl-carousel .owl-nav .owl-next, .owl-carousel .owl-dot {
  cursor: pointer;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.owl-carousel .owl-nav button.owl-prev, .owl-carousel .owl-nav button.owl-next, .owl-carousel button.owl-dot {
  background: rgba(255, 255, 255, 0.8);
  color: inherit;
  border: #dee2e6 1px solid;
  padding: 0 5px !important;
  font: inherit;
}

.owl-carousel .owl-dots {
  text-align: center;
}

.owl-carousel button.owl-dot {
  border-radius: 50%;
  margin-left: 10px;
  width: 0.875rem;
  height: 0.875rem;
}

.owl-carousel button.owl-dot.active {
  background: #6c63ff;
}

.owl-carousel .owl-video-tn {
  opacity: 0;
  height: 100%;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  transition: opacity 400ms ease;
}

.owl-carousel .owl-video-frame {
  position: relative;
  z-index: 1;
  height: 100%;
  width: 100%;
}

.owl-carousel .owl-nav .owl-prev, .owl-carousel .owl-nav .owl-next {
  top: 50%;
  margin-top: -1.5rem;
  border-radius: 50%;
  height: 2.5rem;
  width: 2.5rem;
  position: absolute;
  padding: 4px !important;
  color: #000 !important;
  background: rgba(255, 255, 255, 0.8) !important;
}

.owl-carousel .owl-nav .owl-prev:hover, .owl-carousel .owl-nav .owl-next:hover {
  background: #fff !important;
}

.owl-carousel .owl-nav .owl-prev span, .owl-carousel .owl-nav .owl-next span {
  font-size: 1.5rem;
  line-height: 1.5rem;
}

.owl-carousel .animated {
  animation-duration: 1000ms;
  animation-fill-mode: both;
}

.owl-carousel .owl-animated-in {
  z-index: 0;
}

.owl-carousel .owl-animated-out {
  z-index: 1;
}

.owl-carousel .fadeOut {
  animation-name: fadeOut;
}

.owl-carousel.owl-loaded {
  display: block;
}

.owl-carousel.owl-loading {
  opacity: 0;
  display: block;
}

.owl-carousel.owl-hidden {
  opacity: 0;
}

.owl-carousel.owl-refresh .owl-item {
  visibility: hidden;
}

.owl-carousel.owl-drag .owl-item {
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.owl-carousel.owl-grab {
  cursor: move;
  cursor: grab;
}

.owl-carousel.owl-rtl {
  direction: rtl;
}

.owl-carousel.owl-rtl .owl-item {
  float: right;
}

.owl-carousel .owl-item .owl-lazy {
  opacity: 0;
  transition: opacity 400ms ease;
}

.owl-carousel .owl-item .owl-lazy[src^=""], .owl-carousel .owl-item .owl-lazy:not([src]) {
  max-height: 0;
}

.owl-carousel .owl-item img.owl-lazy {
  transform-style: preserve-3d;
}

.owl-carousel .owl-video-wrapper {
  position: relative;
  height: 100%;
  background: #000;
}

.owl-carousel .owl-video-play-icon {
  position: absolute;
  height: 80px;
  width: 80px;
  left: 50%;
  top: 50%;
  margin-left: -40px;
  margin-top: -40px;
  background: url("../vendors/owl-carousel/assets/owl.video.play.png") no-repeat;
  cursor: pointer;
  z-index: 1;
  transition: transform 100ms ease;
}

.owl-carousel .owl-video-play-icon:hover {
  -ms-transform: scale(1.3, 1.3);
  transform: scale(1.3, 1.3);
}

.owl-carousel .owl-video-playing .owl-video-tn,
.owl-carousel .owl-video-playing .owl-video-play-icon {
  display: none;
}

.owl-carousel:hover .owl-nav .owl-prev, .owl-carousel.show-navigation .owl-nav .owl-prev {
  left: 1rem;
  opacity: .8;
}

.owl-carousel:hover .owl-nav .owl-next, .owl-carousel.show-navigation .owl-nav .owl-next {
  right: 1rem;
  opacity: .8;
}

.owl-mx-0 .owl-dots {
  position: absolute;
  right: 15px;
  top: 10px;
}

.no-js .owl-carousel {
  display: block;
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.owl-height {
  transition: height 500ms ease-in-out;
}

.owl-nav .owl-next {
  right: 0;
  opacity: 0;
  -webkit-transition: -webkit-transform .2s;
  transition: -webkit-transform .2s;
  transition: transform .2s;
  transition: transform .2s,-webkit-transform .2s;
}

.owl-nav .owl-prev {
  left: 0;
  opacity: 0;
  -webkit-transition: -webkit-transform .2s;
  transition: -webkit-transform .2s;
  transition: transform .2s;
  transition: transform .2s,-webkit-transform .2s;
}

/*------------------------------------
	Vertical slider
------------------------------------*/
.top-indicator {
  right: 0;
  top: 1rem;
  bottom: inherit;
  left: inherit;
  justify-content: right;
  margin-right: 1rem;
}

.slider-caption {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 10;
  color: #fff;
  background-color: rgba(27, 27, 27, 0.5);
  text-align: left;
  font-size: 1rem;
  padding: 1.333rem;
  border-bottom-left-radius: 2px;
  border-bottom-right-radius: 2px;
}

/*------------------------------------
	Responsive maps
------------------------------------*/
.responsive-maps {
  position: relative;
  padding-bottom: 75%;
  overflow: hidden;
}

.responsive-maps iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100% !important;
  height: 100% !important;
}

/*------------------------------------
	Widget Social
------------------------------------*/
.social-two li, .social-one li {
  float: left;
  width: 49%;
}

.social-two li:nth-child(even), .social-one li:nth-child(even) {
  float: right;
}

.social-two li a, .social-one li a {
  display: block;
  font-size: 14px;
  line-height: 34px;
  border-radius: 0;
}

.social-two li a:hover, .social-one li a:hover {
  opacity: 0.9;
}

.social-two li a:focus, .social-one li a:focus {
  opacity: 0.9;
}

.social-two li a::before, .social-one li a::before {
  float: left;
  font-family: "Font Awesome 5 Brands";
  font-weight: 400;
  font-size: 0.8rem;
  margin-left: 0.8rem;
  vertical-align: inherit;
  border-top: inherit;
  border-right: inherit;
  border-bottom: 0;
  border-left: inherit;
  width: 34px;
  height: 34px;
  font-size: 16px;
  text-align: center;
  background-color: rgba(255, 255, 255, 0.1);
  margin: 0 10px 0 0;
}

.social-two .facebook a::before, .social-one .facebook a::before {
  content: "\f082";
}

.social-two .twitter a::before, .social-one .twitter a::before {
  content: "\f099";
}

.social-two .vk a::before, .social-one .vk a::before {
  content: "\f189";
}

.social-two .instagram a::before, .social-one .instagram a::before {
  content: "\f16d";
}

.social-two .youtube a::before, .social-one .youtube a::before {
  content: "\f167";
}

.social-two .linkedin a::before, .social-one .linkedin a::before {
  content: "\f08c";
}

.social-two .vimeo a::before, .social-one .vimeo a::before {
  content: "\f40a";
}

.social-two .pinterest a::before, .social-one .pinterest a::before {
  content: "\f0d2";
}

.social-two .telegram a::before, .social-one .telegram a::before {
  content: "\f2c6";
}

.social-two .envelope a::before, .social-one .envelope a::before {
  content: "\f0e0";
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
}

.social-three li {
  margin: 0 -1px -1px 0 !important;
}

.social-three li:hover {
  opacity: 0.9;
}

.social-three li:focus {
  opacity: 0.9;
}

.btn-social:hover {
  opacity: 0.9;
}

.btn-social:focus {
  opacity: 0.9;
}

@media (min-width: 768px) {
  .social-two li, .social-one li {
    width: 100%;
  }
}

@media (min-width: 992px) {
  .social-two li {
    width: 49%;
  }
}

/*------------------------------------
  Social color
------------------------------------*/
.text-facebook {
  color: #3b5898 !important;
}

.hover-facebook-text:hover i {
  color: #3b5898 !important;
}

.hover-facebook a:hover i {
  color: #3b5898 !important;
}

.bg-facebook, .btn-facebook {
  background-color: #3b5898 !important;
  color: #fff;
}

.text-twitter {
  color: #55aced !important;
}

.hover-twitter-text:hover i {
  color: #55aced !important;
}

.hover-twitter a:hover i {
  color: #55aced !important;
}

.bg-twitter, .btn-twitter {
  background-color: #55aced !important;
  color: #fff;
}

.text-instagram {
  color: #e1306c !important;
}

.hover-instagram-text:hover i {
  color: #e1306c !important;
}

.hover-instagram a:hover i {
  color: #e1306c !important;
}

.bg-instagram, .btn-instagram {
  background-color: #e1306c !important;
  color: #fff;
}

.text-youtube {
  color: #e02a21 !important;
}

.hover-youtube-text:hover i {
  color: #e02a21 !important;
}

.hover-youtube a:hover i {
  color: #e02a21 !important;
}

.bg-youtube, .btn-youtube {
  background-color: #e02a21 !important;
  color: #fff;
}

.text-vimeo {
  color: #7ac5f2 !important;
}

.hover-vimeo-text:hover i {
  color: #7ac5f2 !important;
}

.hover-vimeo a:hover i {
  color: #7ac5f2 !important;
}

.bg-vimeo, .btn-vimeo {
  background-color: #7ac5f2 !important;
  color: #fff;
}

.text-envelope {
  color: #f2610b !important;
}

.hover-envelope-text:hover i {
  color: #f2610b !important;
}

.hover-envelope a:hover i {
  color: #f2610b !important;
}

.bg-envelope, .btn-envelope {
  background-color: #f2610b !important;
  color: #fff;
}

.text-linkedin {
  color: #3371b7 !important;
}

.hover-linkedin-text:hover i {
  color: #3371b7 !important;
}

.hover-linkedin a:hover i {
  color: #3371b7 !important;
}

.bg-linkedin, .btn-linkedin {
  background-color: #3371b7 !important;
  color: #fff;
}

.text-telegram {
  color: #1187cc !important;
}

.hover-telegram-text:hover i {
  color: #1187cc !important;
}

.hover-telegram a:hover i {
  color: #1187cc !important;
}

.bg-telegram, .btn-telegram {
  background-color: #1187cc !important;
  color: #fff;
}

.text-pinterest {
  color: #c9261a !important;
}

.hover-pinterest-text:hover i {
  color: #c9261a !important;
}

.hover-pinterest a:hover i {
  color: #c9261a !important;
}

.bg-pinterest, .btn-pinterest {
  background-color: #c9261a !important;
  color: #fff;
}

.text-vk {
  color: #5a7fa6 !important;
}

.hover-vk-text:hover i {
  color: #5a7fa6 !important;
}

.hover-vk a:hover i {
  color: #5a7fa6 !important;
}

.bg-vk, .btn-vk {
  background-color: #5a7fa6 !important;
  color: #fff;
}

/*------------------------------------
	Popular post
------------------------------------*/
.post-number {
  counter-reset: my-counter;
}

.post-number li {
  margin-bottom: 0.5rem;
  padding: 0.5rem 0.5rem 1rem;
}

.post-number li a::before {
  font-size: 2rem;
  font-weight: 600;
  counter-increment: my-counter;
  content: counter(my-counter);
  float: left;
  color: #e0e0e0 !important;
  width: 3rem;
  margin-right: 0.5rem;
  line-height: 2rem;
  text-align: center;
}

.post-number li:hover a::before {
  color: #6c63ff !important;
}

/*------------------------------------
	Weather
------------------------------------*/
.weather-icon {
  width: 3rem;
  top: 0;
  position: absolute;
}

.weather-span {
  margin-left: 2.8rem;
}

.weather-small {
  width: 2rem;
}

/*------------------------------------
  Youtube Playlist
------------------------------------*/
.playlist-title {
  max-height: 307px;
  overflow: auto;
}

.playlist-title li {
  padding: 5px 0px;
}

.playlist-title li:hover {
  background-color: #1b1b1b;
  cursor: pointer;
}

.playlist-title li.active {
  background-color: #1b1b1b;
  cursor: pointer;
}

.playlist-title li.active .video-thumb {
  border: #6c63ff 1px solid;
}

.playlist-title li .video-thumb {
  margin-right: 15px;
  display: inline-block;
  vertical-align: middle;
  border: 2px solid transparent;
}

.playlist-title li .video-title {
  margin: 0;
  display: inline;
  vertical-align: middle;
  text-align: left;
  color: #f6f6f6;
}

.video-full iframe {
  width: 100% !important;
  height: 100% !important;
  border: 0;
}

.video-youtube-area iframe, .video-youtube-area source {
  width: 100% !important;
  border: 0;
  height: 160%;
}

/*------------------------------------
  Widget Score
------------------------------------*/
.match-score {
  text-align: center;
}

.match-score > li {
  padding: 0.3rem 0;
}

.match-score > li:hover {
  background-color: #f6f6f6;
}

.match-title {
  padding: 0.3rem 0;
  background-color: #f6f6f6;
}

.teamname {
  font-weight: 600;
}

/*------------------------------------
  Timeline Blog List
------------------------------------*/
.timeline-post {
  list-style-type: none;
  position: relative;
}

.timeline-post:before {
  content: ' ';
  background: #dee2e6;
  display: inline-block;
  position: absolute;
  left: 1.5rem;
  width: 2px;
  height: 100%;
  z-index: 5;
}

.timeline-post > li {
  margin: 1rem 0;
  padding-left: 0.875rem;
  padding-right: 0.875rem;
}

.timeline-post > li:first-child {
  margin-top: 0;
}

.timeline-post > li:before {
  content: ' ';
  background: #fff;
  display: inline-block;
  position: absolute;
  margin-top: .3rem;
  border-radius: 50%;
  border: 3px solid #dee2e6;
  left: 1rem;
  width: 1rem;
  height: 1rem;
  z-index: 5;
}

.timeline-post > li:hover:before {
  background: #6c63ff !important;
}

.timeline-post > li a .timeline-date, .timeline-post > li a .timeline-title {
  padding-left: 1.5rem;
}

.timeline-post > li a .timeline-date:hover, .timeline-post > li a .timeline-title:hover {
  color: #6c63ff !important;
}

/*------------------------------------
  Widget
------------------------------------*/
.widget-title {
  text-transform: uppercase;
  margin-bottom: 1rem;
  padding: 0.5rem 0;
}

.widget ul, .widget ol {
  padding-left: 0;
  list-style: none;
}

.widget ul li, .widget ol li {
  margin-bottom: 0.5rem;
}

.widget ul li a, .widget ol li a {
  color: #1b1b1b;
}

.widget ul li a.url, .widget ol li a.url {
  color: #6c63ff;
  font-style: italic;
}

.widget ul li a:hover, .widget ol li a:hover {
  color: #6c63ff;
}

.widget ul li a.active:hover, .widget ol li a.active:hover {
  color: #fff;
}

.widget ul.no-mb li, .widget ol.no-mb li {
  margin-bottom: -1px;
}

.widget ul.custom-category li, .widget ol.custom-category li {
  margin-bottom: 0;
}

.footer-dark .widget ul li a, .footer-dark .widget ol li a {
  color: #dee2e6;
}

.footer-dark .widget ul li a.url, .footer-dark .widget ol li a.url {
  color: #6c63ff;
  font-style: italic;
}

.footer-dark .widget ul li a:hover, .footer-dark .widget ol li a:hover {
  color: #6c63ff;
}

.tagcloud li {
  display: inline;
}

/*------------------------------------
  Avatar
------------------------------------*/
.avatar-user {
  margin: 0.5rem auto;
  text-align: center;
}

.card-avatar {
  border: #fff 5px solid;
}

.avatar-rounded {
  border-radius: 3rem;
}

.avatar-xs {
  width: 1.5625rem;
  height: 1.5625rem;
}

.avatar-sm {
  width: 3.125rem;
  height: 3.125rem;
}

.avatar-md {
  width: 4.6875rem;
  height: 4.6875rem;
}

.avatar-lg {
  width: 6.25rem;
  height: 6.25rem;
}

.avatar-xl {
  width: 10rem;
  height: 10rem;
}

.nav-avatar {
  padding: 0rem 1rem;
}

/*------------------------------------
	Five Grid Column
------------------------------------*/
.col-24,
.col-sm-24,
.col-md-24,
.col-lg-24,
.col-xl-24 {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
}

.col-24 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 20%;
  flex: 0 0 20%;
  max-width: 20%;
}

.offset-24 {
  margin-left: 20%;
}

.col-24 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 20%;
  flex: 0 0 20%;
  max-width: 20%;
}

.offset-24 {
  margin-left: 20%;
}

@media (min-width: 576px) {
  .col-sm-24 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 20%;
    flex: 0 0 20%;
    max-width: 20%;
  }
  .offset-sm-24 {
    margin-left: 20%;
  }
}

@media (min-width: 768px) {
  .col-md-24 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 20%;
    flex: 0 0 20%;
    max-width: 20%;
  }
  .offset-md-24 {
    margin-left: 20%;
  }
}

@media (min-width: 992px) {
  .col-lg-24 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 20%;
    flex: 0 0 20%;
    max-width: 20%;
  }
  .offset-lg-24 {
    margin-left: 20%;
  }
}

@media (min-width: 1200px) {
  .col-xl-24 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 20%;
    flex: 0 0 20%;
    max-width: 20%;
  }
  .offset-xl-24 {
    margin-left: 20%;
  }
}

/*------------------------------------
	Member area
------------------------------------*/
.sidebar-item.active, .sidebar-item:hover {
  background-color: #fff;
  border: 1px solid #dee2e6;
  border-right: 0;
  margin-right: -1px;
}

.sidebar-menu {
  border-bottom: 1px solid #dee2e6;
  border-top: 1px solid #dee2e6;
  border-right: 1px solid #dee2e6;
  padding-bottom: 1rem;
  padding-top: 1rem;
  background-color: #f6f6f6;
}

.sidebar-menu li a.active, .sidebar-menu li a:hover {
  background-color: #fff;
}

.sidebar-item {
  position: relative;
  display: block;
  padding: 0.75rem 1.25rem;
  margin-bottom: 0.5rem;
  border: 1px solid transparent;
}

.sidebar-item.active:hover {
  background-color: #fff;
}

.card .icon-big {
  font-size: 3rem;
}

.card .icon-big .notif {
  position: absolute;
  min-width: 25px;
  border-radius: 5rem;
  font-size: 1rem;
  background: #d72924;
  color: #fff;
}

.card .numbers {
  text-align: right;
}

.card .numbers p {
  font-size: 1rem;
  margin: 0;
}

.card .footer {
  padding: 0;
  line-height: 30px;
}

.side-notif {
  padding-left: 7px;
  padding-right: 7px;
  font-size: 0.8rem;
  border-radius: 5rem;
  background-color: #d72924;
  color: #fff;
}

.statistics li {
  padding: 0.25rem 0;
}

.member-item:hover .card {
  background-color: #f6f6f6;
}

.table-striped tbody tr:nth-of-type(odd) {
  background-color: #f6f6f6;
}

@media (max-width: 767.98px) {
  .sidebar-item.active, .sidebar-item:hover {
    border-right: 1px solid #dee2e6;
    margin-left: 0.25rem;
    margin-right: 0.25rem;
  }
}

/*------------------------------------
	Progress bars
------------------------------------*/
#progress-bar {
  z-index: 2000;
  background: rgba(255, 255, 255, 0.5);
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  border: none;
}

.progress-one {
  height: 2px;
}

.progress-two {
  height: 4px;
}

.progress-three {
  height: 5px;
}

progress {
  color: #6c63ff;
}

progress::-webkit-progress-value, progress::-moz-progress-bar, progress::-webkit-progress-value, progress::-webkit-progress-bar {
  background: #6c63ff;
}

/*------------------------------------
	Background images
------------------------------------*/
.boxed .bg-image, .framed .bg-image {
  top: 0;
  left: 0;
  z-index: 0;
  overflow: hidden;
  width: 100%;
  height: 100%;
}

.bg-cover .bg-image, .bg-cover2 .bg-image, .bg-cover3 .bg-image {
  background-color: #fff;
  position: fixed;
  background-repeat: no-repeat;
  background-size: cover;
}

.bg-repeat .bg-image, .bg-repeat2 .bg-image, .bg-repeat3 .bg-image {
  background-color: #fff;
  position: fixed;
  background-repeat: repeat;
}

.bg-cover .bg-image {
  background-image: url(../img/background/bg1.jpg);
}

.bg-cover2 .bg-image {
  background-image: url(../img/background/bg2.jpg);
}

.bg-cover3 .bg-image {
  background-image: url(../img/background/bg3.jpg);
}

.bg-repeat .bg-image {
  background-image: url(../img/background/pattern1.png);
}

.bg-repeat2 .bg-image {
  background-image: url(../img/background/pattern2.png);
}

.bg-repeat3 .bg-image {
  background-image: url(../img/background/pattern3.png);
}

.bg-pattern-repeat {
  background: #f6f6f6 url(../img/background/pattern4.png) repeat;
}

/*------------------------------------
	Background Shadow
------------------------------------*/
.bg-shadow {
  background: linear-gradient(to bottom, rgba(179, 171, 171, 0) 0%, rgba(48, 48, 48, 0.71) 49%, rgba(19, 19, 19, 0.8) 100%);
}

.bg-lg-shadow {
  background: linear-gradient(to bottom, rgba(16, 11, 11, 0) 0%, rgba(0, 0, 0, 0.67) 49%, rgba(19, 19, 19, 0.88) 100%);
}

.bg-shadow-two {
  background: rgba(53, 53, 53, 0.75);
}

.bg-full-shadow {
  position: absolute;
  background: #000;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: .2;
  transition: opacity 0.3s;
  -webkit-transition: opacity 0.3s;
  -moz-transition: opacity 0.3s;
  z-index: 2;
}

/*------------------------------------
	Video Background
------------------------------------*/
.background-video {
  position: relative;
  background-color: #000;
  height: 75vh;
  min-height: 25rem;
  width: 100%;
  overflow: hidden;
}

.background-video iframe {
  position: absolute;
  top: 50%;
  left: 50%;
  min-width: 100%;
  min-height: 100%;
  z-index: 0;
  -ms-transform: translateX(-50%) translateY(-50%);
  -moz-transform: translateX(-50%) translateY(-50%);
  -webkit-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
}

.background-video video {
  width: auto;
  height: auto;
}

.background-video .video-youtube-area {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.background-video .container {
  position: relative;
  z-index: 2;
}

.background-video .overlay {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: #000;
  opacity: 0.5;
  z-index: 1;
}

.mini-line {
  margin: 1rem auto;
  width: 6rem;
  border-top: #6c63ff 3px solid;
}

@media (max-width: 767.98px) {
  .background-video {
    height: 30vh;
    min-height: 0;
  }
}

@media (min-width: 576px) {
  .background-video {
    height: 40vh;
    min-height: 20rem;
  }
}

@media (min-width: 768px) {
  .background-video {
    height: 50vh;
    min-height: 20rem;
  }
}

@media (min-width: 992px) {
  .background-video {
    height: 75vh;
    min-height: 25rem;
  }
}

/*------------------------------------
	Hover
------------------------------------*/
.hover-show {
  opacity: 0;
}

.hover-a:hover a.h1, .hover-a:hover a.h2, .hover-a:hover a.h3, .hover-a:hover a.h4, .hover-a:hover a.h5, .hover-a:hover a.h6 {
  color: #6c63ff;
}

.hover-a:hover h1 > a, .hover-a:hover h2 > a, .hover-a:hover h3 > a, .hover-a:hover h4 > a, .hover-a:hover h5 > a, .hover-a:hover h6 > a, .hover-a:hover .h1 > a, .hover-a:hover .h2 > a, .hover-a:hover .h3 > a, .hover-a:hover .h4 > a, .hover-a:hover .h5 > a, .hover-a:hover .h6 > a, .hover-a:hover p > a {
  color: #6c63ff;
}

.hover-a:hover .bg-full-shadow {
  opacity: 0.5;
}

.hover-a:hover .hover-block, .hover-a:hover .hover-show, .hover-a:hover .caret-up img, .hover-a:hover .caret-down img, .hover-a:hover .caret-left img, .hover-a:hover .caret-right img, .hover-a:hover img.card-avatar, .hover-a:hover .fa-stack-sea {
  opacity: 1;
}

.hover-a:hover .hover-hide {
  opacity: 0;
}

.hover-a li:hover a {
  color: #6c63ff;
}

.hover-a li:hover a.h6 {
  color: #6c63ff;
}

.hover-zoomin img {
  opacity: 1;
  transition: opacity 1s,transform 1s;
}

.hover-zoomin:hover img {
  opacity: .9;
  transform: scale3d(1.1, 1.1, 1);
}

.fa-stack-sea {
  opacity: .7;
  background-color: #fff;
  border: #6c63ff 3px solid;
  border-radius: 3rem;
  display: inline-block;
  height: 2.5em;
  line-height: 2em;
  position: relative;
  vertical-align: middle;
  width: 2.5em;
}

.fa-stack-sea.stack-2x {
  padding: 0.8rem;
  height: 4rem;
  width: 4rem;
}

.hover-block {
  opacity: 0;
}

a:hover {
  color: #6c63ff;
}

h1 > a:hover, h2 > a:hover, h3 > a:hover, h4 > a:hover, h5 > a:hover, h6 > a:hover, .h1 > a:hover, .h2 > a:hover, .h3 > a:hover, .h4 > a:hover, .h5 > a:hover, .h6 > a:hover {
  color: #6c63ff;
}

.hover-link li a:hover {
  background-color: #f6f6f6;
}

.cursor-pointer {
  cursor: pointer;
}

/*------------------------------------
	Zoom Effect
------------------------------------*/
.overflow {
  position: relative;
  overflow: hidden;
}

.overflow img {
  position: relative;
  display: block;
}

.overflow video {
  position: relative;
  display: block;
}

.zoom img {
  transition: all 0.2s linear;
}

.zoom video {
  transition: all 0.2s linear;
}

.zoom:hover img {
  transform: scale(1.1);
}

.zoom:hover video {
  transform: scale(1.1);
}

/*------------------------------------
	Typograpy
------------------------------------*/
.h1 {
  font-size: 1.5rem;
}

.h2 {
  font-size: 1.375rem;
}

.h3 {
  font-size: 1.25rem;
}

.h4 {
  font-size: 1.125rem;
}

.h5 {
  font-size: 1rem;
}

.h6 {
  font-size: 0.875rem;
}

.lead {
  font-size: 1.25rem;
  font-weight: 300;
}

.display-1 {
  font-size: 3.6rem;
  font-weight: 400;
  line-height: 1.2;
}

.display-2 {
  font-size: 2.4rem;
  font-weight: 400;
  line-height: 1.2;
}

.display-3 {
  font-size: 2rem;
  font-weight: 400;
  line-height: 1.2;
}

.display-4 {
  font-size: 1.8rem;
  font-weight: 400;
  line-height: 1.2;
}

@media (min-width: 576px) {
  .h1-sm {
    font-size: 1.5rem;
  }
  .h2-sm {
    font-size: 1.375rem;
  }
  .h3-sm {
    font-size: 1.25rem;
  }
  .h4-sm {
    font-size: 1.125rem;
  }
  .h5-sm {
    font-size: 1rem;
  }
  .h6-sm {
    font-size: 0.875rem;
  }
  .lead-sm {
    font-size: 1.25rem;
    font-weight: 300;
  }
  .display-1-sm {
    font-size: 3.6rem;
    font-weight: 400;
    line-height: 1.2;
  }
  .display-2-sm {
    font-size: 2.4rem;
    font-weight: 400;
    line-height: 1.2;
  }
  .display-3-sm {
    font-size: 2rem;
    font-weight: 400;
    line-height: 1.2;
  }
  .display-4-sm {
    font-size: 1.8rem;
    font-weight: 400;
    line-height: 1.2;
  }
}

@media (min-width: 768px) {
  .h1-md {
    font-size: 1.5rem;
  }
  .h2-md {
    font-size: 1.375rem;
  }
  .h3-md {
    font-size: 1.25rem;
  }
  .h4-md {
    font-size: 1.125rem;
  }
  .h5-md {
    font-size: 1rem;
  }
  .h6-md {
    font-size: 0.875rem;
  }
  .lead-md {
    font-size: 1.25rem;
    font-weight: 300;
  }
  .display-1-md {
    font-size: 3.6rem;
    font-weight: 400;
    line-height: 1.2;
  }
  .display-2-md {
    font-size: 2.4rem;
    font-weight: 400;
    line-height: 1.2;
  }
  .display-3-md {
    font-size: 2rem;
    font-weight: 400;
    line-height: 1.2;
  }
  .display-4-md {
    font-size: 1.8rem;
    font-weight: 400;
    line-height: 1.2;
  }
}

@media (min-width: 992px) {
  .h1-lg {
    font-size: 1.5rem;
  }
  .h2-lg {
    font-size: 1.375rem;
  }
  .h3-lg {
    font-size: 1.25rem;
  }
  .h4-lg {
    font-size: 1.125rem;
  }
  .h5-lg {
    font-size: 1rem;
  }
  .h6-lg {
    font-size: 0.875rem;
  }
  .lead-lg {
    font-size: 1.25rem;
    font-weight: 300;
  }
  .display-1-lg {
    font-size: 3.6rem;
    font-weight: 400;
    line-height: 1.2;
  }
  .display-2-lg {
    font-size: 2.4rem;
    font-weight: 400;
    line-height: 1.2;
  }
  .display-3-lg {
    font-size: 2rem;
    font-weight: 400;
    line-height: 1.2;
  }
  .display-4-lg {
    font-size: 1.8rem;
    font-weight: 400;
    line-height: 1.2;
  }
}

@media (min-width: 1200px) {
  .h1-xl {
    font-size: 1.5rem;
  }
  .h2-xl {
    font-size: 1.375rem;
  }
  .h3-xl {
    font-size: 1.25rem;
  }
  .h4-xl {
    font-size: 1.125rem;
  }
  .h5-xl {
    font-size: 1rem;
  }
  .h6-xl {
    font-size: 0.875rem;
  }
  .lead-xl {
    font-size: 1.25rem;
    font-weight: 300;
  }
  .display-1-xl {
    font-size: 3.6rem;
    font-weight: 400;
    line-height: 1.2;
  }
  .display-2-xl {
    font-size: 2.4rem;
    font-weight: 400;
    line-height: 1.2;
  }
  .display-3-xl {
    font-size: 2rem;
    font-weight: 400;
    line-height: 1.2;
  }
  .display-4-xl {
    font-size: 1.8rem;
    font-weight: 400;
    line-height: 1.2;
  }
}

@media (max-width: 575.98px) {
  .text-center-down {
    text-align: center;
  }
}

@media (max-width: 767.98px) {
  .text-center-sm-down {
    text-align: center;
  }
}

@media (max-width: 991.98px) {
  .text-center-md-down {
    text-align: center;
  }
}

@media (max-width: 1199.98px) {
  .text-center-lg-down {
    text-align: center;
  }
}

.text-center-xl-down {
  text-align: center;
}

/*------------------------------------
	Gradient images efect
------------------------------------*/
.gradient > a:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: .6;
  display: block;
}

.gradient-1 > a:after {
  background: linear-gradient(45deg, #211d6b 0, #ce381c 100%);
}

.gradient-2 > a:after {
  background: linear-gradient(45deg, #18256f 0, #1fe6e4 100%);
}

.gradient-3 > a:after {
  background: linear-gradient(45deg, #187957 0, #52e253 100%);
}

.gradient-4 > a:after {
  background: linear-gradient(45deg, #9a8700 0, #dbff16 100%);
}

.gradient-5 > a:after {
  background: linear-gradient(45deg, #971d7d 0, #f93c3c 100%);
}

.gradient-6 > a:after {
  background: linear-gradient(45deg, #97931d 0, #0889bb 100%);
}

/*------------------------------------
	Box Shadow
------------------------------------*/
.shadow-lrb-lg {
  box-shadow: 0 0.4rem 2.2rem 0 rgba(27, 30, 36, 0.1);
}

.shadow-lrb-sm {
  box-shadow: 0 0.1rem 0.6rem 0 rgba(27, 30, 36, 0.1);
}

.shadow-b {
  box-shadow: 0 0.5rem 1rem -0.5rem rgba(0, 0, 0, 0.1);
}

.shadow-b-sm {
  box-shadow: 0 0.5rem 0.5rem -0.5rem rgba(0, 0, 0, 0.1);
}

.shadow-t {
  box-shadow: 0 -0.5rem 1rem -0.5rem rgba(0, 0, 0, 0.1);
}

.shadow-r {
  box-shadow: 0.5rem 0 1rem -0.5rem rgba(0, 0, 0, 0.1);
}

.shadow-l {
  box-shadow: -0.5rem 0 1rem -0.5rem rgba(0, 0, 0, 0.1);
}

.no-shadow {
  box-shadow: none !important;
}

/*------------------------------------
	Dark Skin
------------------------------------*/
.dark-skin {
  color: #666666;
  background-color: #1b1b1b;
}

.dark-skin .bg-image {
  background-color: #1b1b1b;
}

.dark-skin.full-width {
  background: #1b1b1b !important;
}

.dark-skin pre {
  color: #f6f6f6;
  background: #000;
}

.dark-skin .logo-sidenav a:before {
  background: #495057;
}

.dark-skin .widget ol li a, .dark-skin .widget ul li a {
  color: #d0d0d0;
}

.dark-skin .widget .recentcomments .comment-author-link {
  color: #f6f6f6;
}

.dark-skin a.badge {
  color: #f6f6f6;
  background-color: #6c63ff;
}

.dark-skin a.badge.badge-light {
  color: #f6f6f6;
  background-color: #1b1b1b;
}

.dark-skin a.btn {
  color: #fff;
}

.dark-skin a.btn.btn-light, .dark-skin a.btn.btn-warning {
  color: #1b1b1b;
}

.dark-skin a.back-top {
  color: #f6f6f6;
}

.dark-skin h1, .dark-skin h2, .dark-skin h3, .dark-skin h4, .dark-skin h5, .dark-skin h6, .dark-skin .h1, .dark-skin .h2, .dark-skin .h3, .dark-skin .h4, .dark-skin .h5, .dark-skin .h6 {
  color: #f6f6f6;
}

.dark-skin h1 > a, .dark-skin h2 > a, .dark-skin h3 > a, .dark-skin h4 > a, .dark-skin h5 > a, .dark-skin h6 > a, .dark-skin .h1 > a, .dark-skin .h2 > a, .dark-skin .h3 > a, .dark-skin .h4 > a, .dark-skin .h5 > a, .dark-skin .h6 > a {
  color: #f6f6f6;
}

.dark-skin .bg-themes {
  background: #1b1b1b !important;
}

.dark-skin .footer-dark {
  background-color: #111111 !important;
}

.dark-skin .hover-a:hover h1 > a, .dark-skin .hover-a:hover h2 > a, .dark-skin .hover-a:hover h3 > a, .dark-skin .hover-a:hover h4 > a, .dark-skin .hover-a:hover h5 > a, .dark-skin .hover-a:hover h6 > a, .dark-skin .hover-a:hover .h1 > a, .dark-skin .hover-a:hover .h2 > a, .dark-skin .hover-a:hover .h3 > a, .dark-skin .hover-a:hover .h4 > a, .dark-skin .hover-a:hover .h5 > a, .dark-skin .hover-a:hover .h6 > a {
  color: #6c63ff !important;
}

.dark-skin .hover-a:hover h1 > a.text-white, .dark-skin .hover-a:hover h2 > a.text-white, .dark-skin .hover-a:hover h3 > a.text-white, .dark-skin .hover-a:hover h4 > a.text-white, .dark-skin .hover-a:hover h5 > a.text-white, .dark-skin .hover-a:hover h6 > a.text-white, .dark-skin .hover-a:hover .h1 > a.text-white, .dark-skin .hover-a:hover .h2 > a.text-white, .dark-skin .hover-a:hover .h3 > a.text-white, .dark-skin .hover-a:hover .h4 > a.text-white, .dark-skin .hover-a:hover .h5 > a.text-white, .dark-skin .hover-a:hover .h6 > a.text-white {
  color: #fff !important;
}

.dark-skin .sub-card ul li a {
  color: #f6f6f6;
}

.dark-skin .sub-card ul li a:hover {
  color: #6c63ff !important;
}

.dark-skin .nav-tabs .nav-link {
  border-color: #495057;
  color: #f6f6f6;
}

.dark-skin .nav-block-more a.nav-link {
  color: #f6f6f6;
}

.dark-skin .page-link {
  background-color: #1b1b1b;
  border-color: #495057;
}

.dark-skin .dark-menu .nav-tabs .nav-link {
  border-color: #1b1b1b;
}

.dark-skin .sidenav-menu .dropdown-menu {
  background: #1b1b1b;
  border-color: #495057 !important;
}

.dark-skin .side-link li a, .dark-skin .sidebar-link li a {
  color: #f6f6f6;
}

.dark-skin .side-link li a.caret-collapse, .dark-skin .sidebar-link li a.caret-collapse {
  color: #6c63ff;
}

.dark-skin .side-link li a.caret-collapse.collapsed, .dark-skin .sidebar-link li a.caret-collapse.collapsed {
  color: #f6f6f6;
}

.dark-skin .side-link li a.caret-collapse.collapsed:hover, .dark-skin .side-link li a.caret-collapse.collapsed.active, .dark-skin .sidebar-link li a.caret-collapse.collapsed:hover, .dark-skin .sidebar-link li a.caret-collapse.collapsed.active {
  color: #6c63ff;
}

.dark-skin .side-link li a:hover, .dark-skin .side-link li a.active, .dark-skin .sidebar-link li a:hover, .dark-skin .sidebar-link li a.active {
  color: #6c63ff;
}

.dark-skin strong, .dark-skin .dropcaps-one {
  color: #f6f6f6;
}

.dark-skin .main-menu.navbar-light h1, .dark-skin .main-menu.navbar-light h2, .dark-skin .main-menu.navbar-light h3, .dark-skin .main-menu.navbar-light h4, .dark-skin .main-menu.navbar-light h5, .dark-skin .main-menu.navbar-light h6, .dark-skin .main-menu.navbar-light .h1, .dark-skin .main-menu.navbar-light .h2, .dark-skin .main-menu.navbar-light .h3, .dark-skin .main-menu.navbar-light .h4, .dark-skin .main-menu.navbar-light .h5, .dark-skin .main-menu.navbar-light .h6 {
  color: #1b1b1b !important;
}

.dark-skin .main-menu.navbar-light h1 > a, .dark-skin .main-menu.navbar-light h2 > a, .dark-skin .main-menu.navbar-light h3 > a, .dark-skin .main-menu.navbar-light h4 > a, .dark-skin .main-menu.navbar-light h5 > a, .dark-skin .main-menu.navbar-light h6 > a, .dark-skin .main-menu.navbar-light .h1 > a, .dark-skin .main-menu.navbar-light .h2 > a, .dark-skin .main-menu.navbar-light .h3 > a, .dark-skin .main-menu.navbar-light .h4 > a, .dark-skin .main-menu.navbar-light .h5 > a, .dark-skin .main-menu.navbar-light .h6 > a {
  color: #1b1b1b !important;
}

.dark-skin .main-menu.navbar-light h1:hover, .dark-skin .main-menu.navbar-light h2:hover, .dark-skin .main-menu.navbar-light h3:hover, .dark-skin .main-menu.navbar-light h4:hover, .dark-skin .main-menu.navbar-light h5:hover, .dark-skin .main-menu.navbar-light h6:hover, .dark-skin .main-menu.navbar-light .h1:hover, .dark-skin .main-menu.navbar-light .h2:hover, .dark-skin .main-menu.navbar-light .h3:hover, .dark-skin .main-menu.navbar-light .h4:hover, .dark-skin .main-menu.navbar-light .h5:hover, .dark-skin .main-menu.navbar-light .h6:hover {
  color: #6c63ff !important;
}

.dark-skin .main-menu.navbar-light .hover-a:hover h1 > a, .dark-skin .main-menu.navbar-light .hover-a:hover h2 > a, .dark-skin .main-menu.navbar-light .hover-a:hover h3 > a, .dark-skin .main-menu.navbar-light .hover-a:hover h4 > a, .dark-skin .main-menu.navbar-light .hover-a:hover h5 > a, .dark-skin .main-menu.navbar-light .hover-a:hover h6 > a, .dark-skin .main-menu.navbar-light .hover-a:hover .h1 > a, .dark-skin .main-menu.navbar-light .hover-a:hover .h2 > a, .dark-skin .main-menu.navbar-light .hover-a:hover .h3 > a, .dark-skin .main-menu.navbar-light .hover-a:hover .h4 > a, .dark-skin .main-menu.navbar-light .hover-a:hover .h5 > a, .dark-skin .main-menu.navbar-light .hover-a:hover .h6 > a {
  color: #6c63ff !important;
}

.dark-skin .main-menu.navbar-light .dropdown-item {
  color: #1b1b1b;
}

.dark-skin .main-menu.navbar-light .dropdown-item.active {
  color: #6c63ff;
}

.dark-skin .main-menu.navbar-light .vertical-tabs .nav-tabs .nav-link {
  border-color: transparent;
  color: #1b1b1b !important;
}

.dark-skin .main-menu.navbar-light .vertical-tabs .nav-tabs .nav-link.active, .dark-skin .main-menu.navbar-light .vertical-tabs .nav-tabs .nav-link:hover {
  color: #6c63ff !important;
  border-color: #dee2e6 #fff #dee2e6 #fff;
  background-color: #fff;
}

@media (min-width: 992px) {
  .dark-skin .main-menu.navbar-light .hover-mode .main-nav li:hover > a {
    color: #6c63ff;
  }
  .dark-skin .main-menu.navbar-dark .hover-mode .main-nav li:hover > a {
    background-color: #1b1b1b;
  }
  .dark-skin .main-menu.navbar-dark .hover-mode .main-nav .mega-menu li.menu-item-has-children:hover > a {
    background-color: transparent;
  }
}

.dark-skin .card, .dark-skin .sidebar-menu {
  background: #1b1b1b;
}

.dark-skin .bg-light-dark {
  background: #1b1b1b !important;
}

.dark-skin .bg-light-black {
  background: #000 !important;
}

.dark-skin .back-top {
  background: #1b1b1b;
  color: #f6f6f6 !important;
}

.dark-skin .bg-input, .dark-skin .match-title {
  background: #000 !important;
}

.dark-skin .hover-link li a:hover {
  background: #000;
}

.dark-skin .match-score > li:hover {
  background: #000;
}

.dark-skin .ql-toolbar.ql-snow {
  background: #fff;
  border-color: #495057;
}

.dark-skin .bg-pattern-repeat {
  background: url(../img/background/pattern5.png) repeat #f6f6f6;
}

.dark-skin .sidebar-menu li a:hover, .dark-skin .sidebar-menu li a.active, .dark-skin .unread, .dark-skin .table-striped tbody tr:nth-of-type(odd) {
  background-color: #000;
}

.dark-skin .form-control {
  color: #f6f6f6;
  background-color: #000;
  border-color: #495057;
}

.dark-skin .form-control:hover, .dark-skin .form-control:focus {
  background-color: #000;
}

.dark-skin .input-group-text {
  color: #f6f6f6;
  background-color: #1b1b1b;
  border-color: #495057;
}

.dark-skin .list-group-item {
  background-color: transparent;
}

.dark-skin .table {
  color: #f6f6f6;
}

.dark-skin .post-content ul, .dark-skin .post-content ol {
  color: #d0d0d0;
}

.dark-skin p {
  color: #d0d0d0;
}

.dark-skin .tags-list-cat li {
  border-color: #495057;
}

.dark-skin .tags-list li {
  border-color: #495057;
}

.dark-skin .tags-list li:first-child {
  border-color: transparent;
}

.dark-skin .tags-list li:first-child span {
  color: #dee2e6;
}

.dark-skin .u-blockquote, .dark-skin .wp-block-quote {
  background: #000;
}

.dark-skin .close-suggestion i {
  color: #fff;
}

.dark-skin .prev-post-left > a, .dark-skin .next-post-right > a {
  color: #d0d0d0;
}

.dark-skin .prev-post-left > a:hover, .dark-skin .next-post-right > a:hover {
  color: #6c63ff;
}

.dark-skin .comment-list li .user-comment {
  border-color: #495057;
}

.dark-skin .invalid-feedback {
  padding-left: 15px;
  background: #d72924;
  color: #fff;
}

.dark-skin hr, .dark-skin .img-thumbnail, .dark-skin .table th, .dark-skin .table td, .dark-skin .table thead th, .dark-skin .table tbody + tbody, .dark-skin .table-bordered, .dark-skin .table-bordered th,
.dark-skin .table-bordered td, .dark-skin .table .thead-light th, .dark-skin .nav-tabs, .dark-skin .card, .dark-skin .card-header, .dark-skin .card-footer, .dark-skin .page-link, .dark-skin .page-link:hover, .dark-skin .page-item.disabled .page-link, .dark-skin .modal-header, .dark-skin .modal-footer, .dark-skin .ql-container.ql-snow {
  border-color: #495057;
}

.dark-skin .nav-tabs .nav-link:hover, .dark-skin .nav-tabs .nav-link:focus {
  border-color: #1b1b1b #1b1b1b #495057;
}

.dark-skin .nav-tabs .nav-link.active,
.dark-skin .nav-tabs .nav-item.show .nav-link {
  border-color: #495057 #495057 #000;
}

.dark-skin .border, .dark-skin .border-top, .dark-skin .border-right, .dark-skin .border-bottom, .dark-skin .border-left {
  border-color: #495057 !important;
}

.dark-skin .border-smooth {
  border-color: rgba(255, 255, 255, 0.12) !important;
}

.dark-skin .border-smooth-light {
  border-color: #e0e0e0 !important;
}

.dark-skin .block-title-1, .dark-skin .block-title-2, .dark-skin .block-title-3, .dark-skin .block-title-4, .dark-skin .block-title-5, .dark-skin .block-title-5 h1 span, .dark-skin .block-title-5 h2 span, .dark-skin .block-title-5 h3 span, .dark-skin .block-title-5 h4 span, .dark-skin .block-title-5 h5 span, .dark-skin .block-title-5 h6 span,
.dark-skin .block-title-6 h1, .dark-skin .block-title-6 h2, .dark-skin .block-title-6 h3, .dark-skin .block-title-6 h4, .dark-skin .block-title-6 h5, .dark-skin .block-title-6 h6,
.dark-skin .block-title-9 h1 span, .dark-skin .block-title-9 h2 span, .dark-skin .block-title-9 h3 span, .dark-skin .block-title-9 h4 span, .dark-skin .block-title-9 h5 span, .dark-skin .block-title-9 h6 span, .dark-skin .block-title-15 h1, .dark-skin .block-title-15 h2, .dark-skin .block-title-15 h3, .dark-skin .block-title-15 h4, .dark-skin .block-title-15 h5, .dark-skin .block-title-15 h6,
.dark-skin .vertical-tabs .nav.nav-tabs, .dark-skin .list-group-level2, .dark-skin .list-group-level1, .dark-skin .suggestion-box, .dark-skin .comment-list article, .dark-skin .tags-list li, .dark-skin .tags-list-cat li, .dark-skin .sidebar-item.active, .dark-skin .sidebar-item:hover, .dark-skin .sidebar-menu, .dark-skin .messages-list li p, .dark-skin .timeline-post > li:before {
  border-color: #495057;
}

.dark-skin .navbar-nav .vertical-tabs .nav-tabs .nav-link.active {
  border-color: #495057 #000 #495057 #000;
}

.dark-skin .nav-tabs .nav-link {
  border-color: #495057 #495057 #000;
}

.dark-skin .nav-tabs .nav-link:hover, .dark-skin .nav-tabs .nav-link:focus {
  border-color: #495057 #495057 #000;
}

.dark-skin .border-top-first-0 > li, .dark-skin .border-top-first-0 > article, .dark-skin .border-left-first-0 > li, .dark-skin .border-left-first-0 > article, .dark-skin .border-right-last-0 > li, .dark-skin .border-right-last-0 > article, .dark-skin .border-bottom-last-0 > li, .dark-skin .border-bottom-last-0 > article {
  border-color: #495057;
}

.dark-skin .border-top-first-0 > a, .dark-skin .border-left-first-0 > a, .dark-skin .border-right-last-0 > a, .dark-skin .border-bottom-last-0 > a {
  border-color: #495057;
}

.boxed.dark-skin .wrapper, .framed.dark-skin .wrapper {
  box-shadow: 0 0 3px #000;
  background-color: #1b1b1b;
}

.framed.dark-skin .wrapper {
  box-shadow: 0 0 3px #000;
  background-color: rgba(0, 0, 0, 0.7);
}

.framed.dark-skin .wrapper main, .framed.dark-skin .wrapper .second-header {
  background: transparent;
}

.dark-skin .border-top-first-0 > li, .dark-skin .border-top-first-0 > article, .dark-skin .border-left-first-0 > li, .dark-skin .border-left-first-0 > article, .dark-skin .border-right-last-0 > li, .dark-skin .border-right-last-0 > article, .dark-skin .border-bottom-last-0 > li, .dark-skin .border-bottom-last-0 > article {
  border-color: #495057;
}

.dark-skin .border-top-first-0 > a, .dark-skin .border-left-first-0 > a, .dark-skin .border-right-last-0 > a, .dark-skin .border-bottom-last-0 > a {
  border-color: #495057;
}

@media (min-width: 576px) {
  .dark-skin .border-top-first-sm-0 > li, .dark-skin .border-top-first-sm-0 > article, .dark-skin .border-left-first-sm-0 > li, .dark-skin .border-left-first-sm-0 > article, .dark-skin .border-right-last-sm-0 > li, .dark-skin .border-right-last-sm-0 > article, .dark-skin .border-bottom-last-sm-0 > li, .dark-skin .border-bottom-last-sm-0 > article {
    border-color: #495057;
  }
  .dark-skin .border-top-first-sm-0 > a, .dark-skin .border-left-first-sm-0 > a, .dark-skin .border-right-last-sm-0 > a, .dark-skin .border-bottom-last-sm-0 > a {
    border-color: #495057;
  }
}

@media (min-width: 768px) {
  .dark-skin .border-top-first-md-0 > li, .dark-skin .border-top-first-md-0 > article, .dark-skin .border-left-first-md-0 > li, .dark-skin .border-left-first-md-0 > article, .dark-skin .border-right-last-md-0 > li, .dark-skin .border-right-last-md-0 > article, .dark-skin .border-bottom-last-md-0 > li, .dark-skin .border-bottom-last-md-0 > article {
    border-color: #495057;
  }
  .dark-skin .border-top-first-md-0 > a, .dark-skin .border-left-first-md-0 > a, .dark-skin .border-right-last-md-0 > a, .dark-skin .border-bottom-last-md-0 > a {
    border-color: #495057;
  }
}

@media (min-width: 992px) {
  .dark-skin .border-top-first-lg-0 > li, .dark-skin .border-top-first-lg-0 > article, .dark-skin .border-left-first-lg-0 > li, .dark-skin .border-left-first-lg-0 > article, .dark-skin .border-right-last-lg-0 > li, .dark-skin .border-right-last-lg-0 > article, .dark-skin .border-bottom-last-lg-0 > li, .dark-skin .border-bottom-last-lg-0 > article {
    border-color: #495057;
  }
  .dark-skin .border-top-first-lg-0 > a, .dark-skin .border-left-first-lg-0 > a, .dark-skin .border-right-last-lg-0 > a, .dark-skin .border-bottom-last-lg-0 > a {
    border-color: #495057;
  }
}

@media (min-width: 1200px) {
  .dark-skin .border-top-first-xl-0 > li, .dark-skin .border-top-first-xl-0 > article, .dark-skin .border-left-first-xl-0 > li, .dark-skin .border-left-first-xl-0 > article, .dark-skin .border-right-last-xl-0 > li, .dark-skin .border-right-last-xl-0 > article, .dark-skin .border-bottom-last-xl-0 > li, .dark-skin .border-bottom-last-xl-0 > article {
    border-color: #495057;
  }
  .dark-skin .border-top-first-xl-0 > a, .dark-skin .border-left-first-xl-0 > a, .dark-skin .border-right-last-xl-0 > a, .dark-skin .border-bottom-last-xl-0 > a {
    border-color: #495057;
  }
}

/*------------------------------------
	Z index
------------------------------------*/
.z-index-5 {
  z-index: 5;
}

.z-index-10 {
  z-index: 10;
}

.z-index-20 {
  z-index: 20;
}

.z-index-50 {
  z-index: 50;
}

.z-index-100 {
  z-index: 100;
}

.z-index-500 {
  z-index: 500;
}

.z-index-1000 {
  z-index: 1000;
}

/*------------------------------------
 Font weight
------------------------------------*/
.font-weight-500 {
  font-weight: 500;
}

.font-weight-600 {
  font-weight: 600;
}

.font-weight-800 {
  font-weight: 800;
}

.font-weight-900 {
  font-weight: 900;
}

.font-size-1 {
  font-size: 1rem;
}

.font-size-2 {
  font-size: 2rem;
}

.font-size-3 {
  font-size: 3rem;
}

.font-size-4 {
  font-size: 4rem;
}

.font-size-5 {
  font-size: 5rem;
}

.m-module {
  margin: 2rem;
}

.mt-module {
  margin-top: 2rem;
}

.mr-module {
  margin-right: 2rem;
}

.mb-module {
  margin-bottom: 2rem;
}

.ml-module {
  margin-left: 2rem;
}

.mt-minus {
  margin-top: -2.5rem;
}

.mt-minus-sm {
  margin-top: -1rem;
}

.mt-minus-md {
  margin-top: -3rem;
}

.mt-minus-lg {
  margin-top: -6rem;
}

/*------------------------------------
	Space
------------------------------------*/
/*top*/
.t-0 {
  top: 0;
}

.t-1 {
  top: 1rem;
}

.t-2 {
  top: 2rem;
}

.t-3 {
  top: 3rem;
}

.t-4 {
  top: 4rem;
}

/*bottom*/
.b-0 {
  bottom: 0;
}

.b-1 {
  bottom: 1rem;
}

.b-2 {
  bottom: 2rem;
}

.b-3 {
  bottom: 3rem;
}

.b-4 {
  bottom: 4rem;
}

/*right*/
.r-0 {
  right: 0;
}

.r-1 {
  right: 1rem;
}

.r-2 {
  right: 2rem;
}

.r-3 {
  right: 3rem;
}

.r-4 {
  right: 4rem;
}

/*left*/
.l-0 {
  left: 0rem;
}

.l-1 {
  left: 1rem;
}

.l-2 {
  left: 2rem;
}

.l-3 {
  left: 3rem;
}

.l-4 {
  left: 4rem;
}

.no-margin {
  right: 0;
  bottom: 0;
  left: 0;
}

/*------------------------------------
  Space Percent
------------------------------------*/
/*top*/
.top-1 {
  top: 10%;
}

.top-2 {
  top: 20%;
}

.top-3 {
  top: 30%;
}

.top-4 {
  top: 40%;
}

.top-5 {
  top: 50%;
}

/*bottom*/
.bottom-1 {
  bottom: 10%;
}

.bottom-2 {
  bottom: 20%;
}

.bottom-3 {
  bottom: 30%;
}

.bottom-4 {
  bottom: 40%;
}

.bottom-5 {
  bottom: 50%;
}

/*right*/
.right-1 {
  right: 10%;
}

.right-2 {
  right: 20%;
}

.right-3 {
  right: 30%;
}

.right-4 {
  right: 40%;
}

.right-5 {
  right: 50%;
}

/*left*/
.left-1 {
  left: 10%;
}

.left-2 {
  left: 20%;
}

.left-3 {
  left: 30%;
}

.left-4 {
  left: 40%;
}

.left-5 {
  left: 50%;
}

.h-0 {
  height: 0;
}

.gap-0, .gap-05, .gap-075, .gap-1, .gap-15, .gap-2, .gap-25, .gap-3, .gap-4 {
  width: 100%;
  clear: both;
}

.gap-0 {
  height: .1px;
}

.gap-05 {
  height: 0.5rem;
}

.gap-075 {
  height: 0.75rem;
}

.gap-1 {
  height: 1rem;
}

.gap-15 {
  height: 1.5rem;
}

.gap-2 {
  height: 2rem;
}

.gap-25 {
  height: 2.5rem;
}

.gap-3 {
  height: 3rem;
}

.gap-4 {
  height: 4rem;
}

/*------------------------------------
	Border width
------------------------------------*/
.border-none {
  border-width: 0 !important;
}

.border-1 {
  border-width: 1px !important;
}

.border-2 {
  border-width: 2px !important;
}

.border-3 {
  border-width: 3px !important;
}

.border-4 {
  border-width: 4px !important;
}

.border-5 {
  border-width: 5px !important;
}

.border-smooth {
  border-color: rgba(255, 255, 255, 0.12) !important;
}

.border-smooth-light {
  border-color: #e0e0e0 !important;
}

.border-0 {
  border-width: 0px !important;
}

.border-1 {
  border-width: 1px !important;
}

.border-2 {
  border-width: 2px !important;
}

.border-3 {
  border-width: 3px !important;
}

.border-4 {
  border-width: 4px !important;
}

.border-5 {
  border-width: 5px !important;
}

@media (min-width: 576px) {
  .border-sm-0 {
    border-width: 0px !important;
  }
  .border-sm-1 {
    border-width: 1px !important;
  }
  .border-sm-2 {
    border-width: 2px !important;
  }
  .border-sm-3 {
    border-width: 3px !important;
  }
  .border-sm-4 {
    border-width: 4px !important;
  }
  .border-sm-5 {
    border-width: 5px !important;
  }
}

@media (min-width: 768px) {
  .border-md-0 {
    border-width: 0px !important;
  }
  .border-md-1 {
    border-width: 1px !important;
  }
  .border-md-2 {
    border-width: 2px !important;
  }
  .border-md-3 {
    border-width: 3px !important;
  }
  .border-md-4 {
    border-width: 4px !important;
  }
  .border-md-5 {
    border-width: 5px !important;
  }
}

@media (min-width: 992px) {
  .border-lg-0 {
    border-width: 0px !important;
  }
  .border-lg-1 {
    border-width: 1px !important;
  }
  .border-lg-2 {
    border-width: 2px !important;
  }
  .border-lg-3 {
    border-width: 3px !important;
  }
  .border-lg-4 {
    border-width: 4px !important;
  }
  .border-lg-5 {
    border-width: 5px !important;
  }
}

@media (min-width: 1200px) {
  .border-xl-0 {
    border-width: 0px !important;
  }
  .border-xl-1 {
    border-width: 1px !important;
  }
  .border-xl-2 {
    border-width: 2px !important;
  }
  .border-xl-3 {
    border-width: 3px !important;
  }
  .border-xl-4 {
    border-width: 4px !important;
  }
  .border-xl-5 {
    border-width: 5px !important;
  }
}

/*------------------------------------
 Border Child
------------------------------------*/
.border-top-first-0 > li, .border-top-first-0 > article {
  border-top: 1px solid #dee2e6;
}

.border-top-first-0 > li:first-child, .border-top-first-0 > article:first-child {
  border-top: none;
}

.border-top-first-0 > a {
  border-top: 1px solid #dee2e6;
}

.border-top-first-0 > a:first-child {
  border-top: none;
}

.border-left-first-0 > li, .border-left-first-0 > article {
  border-left: 1px solid #dee2e6;
}

.border-left-first-0 > li:first-child, .border-left-first-0 > article:first-child {
  border-left: none;
}

.border-left-first-0 > a {
  border-left: 1px solid #dee2e6;
}

.border-left-first-0 > a:first-child {
  border-left: none;
}

.border-right-last-0 > li, .border-right-last-0 > article {
  border-right: 1px solid #dee2e6;
}

.border-right-last-0 > li:last-child, .border-right-last-0 > article:last-child {
  border-right: none;
}

.border-right-last-0 > a {
  border-right: 1px solid #dee2e6;
}

.border-right-last-0 > a:last-child {
  border-right: none;
}

.border-bottom-last-0 > li, .border-bottom-last-0 > article {
  border-bottom: 1px solid #dee2e6;
}

.border-bottom-last-0 > li:last-child, .border-bottom-last-0 > article:last-child {
  border-bottom: none;
}

.border-bottom-last-0 > a {
  border-bottom: 1px solid #dee2e6;
}

.border-bottom-last-0 > a:last-child {
  border-bottom: none;
}

.hr-after {
  background: #1b1b1b;
  height: 1px;
  width: 5rem;
}

.border-top-first-0 > li, .border-top-first-0 > article {
  border-top: 1px solid #dee2e6;
}

.border-top-first-0 > li:first-child, .border-top-first-0 > article:first-child {
  border-top: none;
}

.border-top-first-0 > a {
  border-top: 1px solid #dee2e6;
}

.border-top-first-0 > a:first-child {
  border-top: none;
}

.border-left-first-0 > li, .border-left-first-0 > article {
  border-left: 1px solid #dee2e6;
}

.border-left-first-0 > li:first-child, .border-left-first-0 > article:first-child {
  border-left: none;
}

.border-left-first-0 > a {
  border-left: 1px solid #dee2e6;
}

.border-left-first-0 > a:first-child {
  border-left: none;
}

.border-right-last-0 > li, .border-right-last-0 > article {
  border-right: 1px solid #dee2e6;
}

.border-right-last-0 > li:last-child, .border-right-last-0 > article:last-child {
  border-right: none;
}

.border-right-last-0 > a {
  border-right: 1px solid #dee2e6;
}

.border-right-last-0 > a:last-child {
  border-right: none;
}

.border-bottom-last-0 > li, .border-bottom-last-0 > article {
  border-bottom: 1px solid #dee2e6;
}

.border-bottom-last-0 > li:last-child, .border-bottom-last-0 > article:last-child {
  border-bottom: none;
}

.border-bottom-last-0 > a {
  border-bottom: 1px solid #dee2e6;
}

.border-bottom-last-0 > a:last-child {
  border-bottom: none;
}

@media (min-width: 576px) {
  .border-top-first-sm-0 > li, .border-top-first-sm-0 > article {
    border-top: 1px solid #dee2e6;
  }
  .border-top-first-sm-0 > li:first-child, .border-top-first-sm-0 > article:first-child {
    border-top: none;
  }
  .border-top-first-sm-0 > a {
    border-top: 1px solid #dee2e6;
  }
  .border-top-first-sm-0 > a:first-child {
    border-top: none;
  }
  .border-left-first-sm-0 > li, .border-left-first-sm-0 > article {
    border-left: 1px solid #dee2e6;
  }
  .border-left-first-sm-0 > li:first-child, .border-left-first-sm-0 > article:first-child {
    border-left: none;
  }
  .border-left-first-sm-0 > a {
    border-left: 1px solid #dee2e6;
  }
  .border-left-first-sm-0 > a:first-child {
    border-left: none;
  }
  .border-right-last-sm-0 > li, .border-right-last-sm-0 > article {
    border-right: 1px solid #dee2e6;
  }
  .border-right-last-sm-0 > li:last-child, .border-right-last-sm-0 > article:last-child {
    border-right: none;
  }
  .border-right-last-sm-0 > a {
    border-right: 1px solid #dee2e6;
  }
  .border-right-last-sm-0 > a:last-child {
    border-right: none;
  }
  .border-bottom-last-sm-0 > li, .border-bottom-last-sm-0 > article {
    border-bottom: 1px solid #dee2e6;
  }
  .border-bottom-last-sm-0 > li:last-child, .border-bottom-last-sm-0 > article:last-child {
    border-bottom: none;
  }
  .border-bottom-last-sm-0 > a {
    border-bottom: 1px solid #dee2e6;
  }
  .border-bottom-last-sm-0 > a:last-child {
    border-bottom: none;
  }
}

@media (min-width: 768px) {
  .border-top-first-md-0 > li, .border-top-first-md-0 > article {
    border-top: 1px solid #dee2e6;
  }
  .border-top-first-md-0 > li:first-child, .border-top-first-md-0 > article:first-child {
    border-top: none;
  }
  .border-top-first-md-0 > a {
    border-top: 1px solid #dee2e6;
  }
  .border-top-first-md-0 > a:first-child {
    border-top: none;
  }
  .border-left-first-md-0 > li, .border-left-first-md-0 > article {
    border-left: 1px solid #dee2e6;
  }
  .border-left-first-md-0 > li:first-child, .border-left-first-md-0 > article:first-child {
    border-left: none;
  }
  .border-left-first-md-0 > a {
    border-left: 1px solid #dee2e6;
  }
  .border-left-first-md-0 > a:first-child {
    border-left: none;
  }
  .border-right-last-md-0 > li, .border-right-last-md-0 > article {
    border-right: 1px solid #dee2e6;
  }
  .border-right-last-md-0 > li:last-child, .border-right-last-md-0 > article:last-child {
    border-right: none;
  }
  .border-right-last-md-0 > a {
    border-right: 1px solid #dee2e6;
  }
  .border-right-last-md-0 > a:last-child {
    border-right: none;
  }
  .border-bottom-last-md-0 > li, .border-bottom-last-md-0 > article {
    border-bottom: 1px solid #dee2e6;
  }
  .border-bottom-last-md-0 > li:last-child, .border-bottom-last-md-0 > article:last-child {
    border-bottom: none;
  }
  .border-bottom-last-md-0 > a {
    border-bottom: 1px solid #dee2e6;
  }
  .border-bottom-last-md-0 > a:last-child {
    border-bottom: none;
  }
}

@media (min-width: 992px) {
  .border-top-first-lg-0 > li, .border-top-first-lg-0 > article {
    border-top: 1px solid #dee2e6;
  }
  .border-top-first-lg-0 > li:first-child, .border-top-first-lg-0 > article:first-child {
    border-top: none;
  }
  .border-top-first-lg-0 > a {
    border-top: 1px solid #dee2e6;
  }
  .border-top-first-lg-0 > a:first-child {
    border-top: none;
  }
  .border-left-first-lg-0 > li, .border-left-first-lg-0 > article {
    border-left: 1px solid #dee2e6;
  }
  .border-left-first-lg-0 > li:first-child, .border-left-first-lg-0 > article:first-child {
    border-left: none;
  }
  .border-left-first-lg-0 > a {
    border-left: 1px solid #dee2e6;
  }
  .border-left-first-lg-0 > a:first-child {
    border-left: none;
  }
  .border-right-last-lg-0 > li, .border-right-last-lg-0 > article {
    border-right: 1px solid #dee2e6;
  }
  .border-right-last-lg-0 > li:last-child, .border-right-last-lg-0 > article:last-child {
    border-right: none;
  }
  .border-right-last-lg-0 > a {
    border-right: 1px solid #dee2e6;
  }
  .border-right-last-lg-0 > a:last-child {
    border-right: none;
  }
  .border-bottom-last-lg-0 > li, .border-bottom-last-lg-0 > article {
    border-bottom: 1px solid #dee2e6;
  }
  .border-bottom-last-lg-0 > li:last-child, .border-bottom-last-lg-0 > article:last-child {
    border-bottom: none;
  }
  .border-bottom-last-lg-0 > a {
    border-bottom: 1px solid #dee2e6;
  }
  .border-bottom-last-lg-0 > a:last-child {
    border-bottom: none;
  }
}

@media (min-width: 1200px) {
  .border-top-first-xl-0 > li, .border-top-first-xl-0 > article {
    border-top: 1px solid #dee2e6;
  }
  .border-top-first-xl-0 > li:first-child, .border-top-first-xl-0 > article:first-child {
    border-top: none;
  }
  .border-top-first-xl-0 > a {
    border-top: 1px solid #dee2e6;
  }
  .border-top-first-xl-0 > a:first-child {
    border-top: none;
  }
  .border-left-first-xl-0 > li, .border-left-first-xl-0 > article {
    border-left: 1px solid #dee2e6;
  }
  .border-left-first-xl-0 > li:first-child, .border-left-first-xl-0 > article:first-child {
    border-left: none;
  }
  .border-left-first-xl-0 > a {
    border-left: 1px solid #dee2e6;
  }
  .border-left-first-xl-0 > a:first-child {
    border-left: none;
  }
  .border-right-last-xl-0 > li, .border-right-last-xl-0 > article {
    border-right: 1px solid #dee2e6;
  }
  .border-right-last-xl-0 > li:last-child, .border-right-last-xl-0 > article:last-child {
    border-right: none;
  }
  .border-right-last-xl-0 > a {
    border-right: 1px solid #dee2e6;
  }
  .border-right-last-xl-0 > a:last-child {
    border-right: none;
  }
  .border-bottom-last-xl-0 > li, .border-bottom-last-xl-0 > article {
    border-bottom: 1px solid #dee2e6;
  }
  .border-bottom-last-xl-0 > li:last-child, .border-bottom-last-xl-0 > article:last-child {
    border-bottom: none;
  }
  .border-bottom-last-xl-0 > a {
    border-bottom: 1px solid #dee2e6;
  }
  .border-bottom-last-xl-0 > a:last-child {
    border-bottom: none;
  }
}

/*------------------------------------
	Mobile screen
------------------------------------*/
@media (max-width: 991.98px) {
  .container {
    max-width: 100%;
  }
}

@media (max-width: 767.98px) {
  .w-100-mobile {
    width: 100% !important;
  }
}
